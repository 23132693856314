import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faHouse,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect, useRef } from "react";
import {
  getAllTask,
  actDeactTask,
  deleteTaskData,
} from "../../../services/Endpoints";
import { getData, deleteData, acivateDeactivate } from "../../../services/API";
import { StatusBtn } from "../../../components/common/StatusBtns";
import { DataGridActionBtns } from "../../../components/common/DataGridActionBtns";
import activateImg from "../../../assets/activate.gif";
import deleteImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
import { ExportToExcel } from "../../../services/ExportToFile";

export interface IManageTaskProps {}

export interface ITaskTabData {
  taskId: number;
  projectId: number;
  activityId: number;
  taskCode: string;
  taskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: number;
  costCode: string;
  taktCode: string;
  p6code: string;
  uomId: number;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: string;
  uom: string;
  activity: string;
  taskSubTaskMappingMasters: any;
  selectedSubTasks: any;
}

export function ManageTask() {
  //Declarations
  const reRoute = useNavigate();
  const location = useLocation();

  const { userData, projectData, companyData } = useAuth();

  const [taskRowData, setTaskRowData] = useState<ITaskTabData[]>();
  const [selectedData, setSelectedData] = useState<any>({});

  const [showToggleConfirm, setShowToggleConfirm] = useState(false);
  const [showToggleAlert, setShowToggleAlert] = useState(false);
  const [toggleMessage, setToggleMessage] = useState("");

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const gridRef = useRef<AgGridReact<ITaskTabData>>(null);
  const [selectedIds, setSelectedIds] = useState("");
  const [enableDeleteAll, setEnableDeleteAll] = useState(false);
  const [showDeleteAllConfirm, setshowDeleteAllConfirm] = useState(false);
  const [showDeleteAllAlert, setShowDeleteAllAlert] = useState(false);
  const [deleteAllMessage, setDeleteAllMessage] = useState("");

  const handleAddBtn = () => {
    reRoute("/addTask");
  };

  const onEditClickHandler = (row: any) => {
    var id = row.taskId;
    reRoute("/editTask/" + id);
  };

  const onDeleteClickHandler = (row: any) => {
    setDeleteMessage("Are you sure to  Delete this record ?");
    setshowDeleteConfirm(true);
    setSelectedData({
      taskId: row.taskId,
      userId: userData.userId,
    });
  };

  const handleDeleteConfirm = () => {
    deleteTask(selectedData.taskId, selectedData.userId);
    closeDeleteConfirm();
  };

  const handleDeleteAlert = () => {
    fetchTaskData();
    closeDeleteAlert();
  };

  const closeDeleteConfirm = () => {
    setshowDeleteConfirm(false);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteCancel = () => {
    closeDeleteConfirm();
  };

  const onSwitchClickHandler = (row: any) => {
    setToggleMessage(
      `Are you sure to ${
        row.activeFlag === 0 ? "Activate" : "Deactivate"
      } this Task ?`
    );
    setShowToggleConfirm(true);
    setSelectedData({
      taskId: row.taskId,
      userId: userData.userId,
    });
  };

  const handleToggleConfirm = () => {
    activateDeactivateTask(selectedData.taskId, selectedData.userId);
    closeToggleConfirm();
  };

  const handleToggleAlert = () => {
    fetchTaskData();
    closeToggleAlert();
  };

  const closeToggleConfirm = () => {
    setShowToggleConfirm(false);
  };

  const closeToggleAlert = () => {
    setShowToggleAlert(false);
  };

  const handleToggleCancel = () => {
    closeToggleConfirm();
  };

  // Datagrid table data
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Activity Name",
      field: "activity",
      filter: true,
      tooltipField: "activity",
      headerTooltip: "Activity Name",
    },
    {
      headerName: "Task Code",
      field: "taskCode",
      filter: true,
      tooltipField: "taskCode",
      headerTooltip: "Task Code",
    },
    {
      headerName: "Task Name",
      field: "taskName",
      filter: true,
      tooltipField: "taskName",
      headerTooltip: "Task Name",
    },
    {
      headerName: "UOM",
      field: "uom",
      filter: true,
      tooltipField: "uom",
      headerTooltip: "UOM",
    },
    {
      headerName: "Planed Start Date",
      field: "planedStartDate",
      filter: true,
      tooltipField: "planedStartDate",
      headerTooltip: "Planed Start Date",
    },
    {
      headerName: "Planed Finish Date",
      field: "planedFinishDate",
      filter: true,
      tooltipField: "planedFinishDate",
      headerTooltip: "Planed Finish Date",
    },
    {
      headerName: "Cost Code",
      field: "costCode",
      filter: true,
      tooltipField: "costCode",
      headerTooltip: "Cost Code",
    },
    {
      headerName: "Takt Code",
      field: "taktCode",
      filter: true,
      tooltipField: "taktCode",
      headerTooltip: "Takt Code",
    },
    {
      headerName: "P6 Code",
      field: "p6code",
      filter: true,
      tooltipField: "p6code",
      headerTooltip: "P6 Code",
    },
    {
      headerName: "Created On",
      field: "createdTime",
      filter: true,
      tooltipField: "createdTime",
      headerTooltip: "Created On",
    },
    {
      headerName: "Status",
      cellRenderer: StatusBtn,
      field: "activeFlag",
      filter: false,
      sortable: false,
      width: 150,
      pinned: "right",
    },
    {
      headerName: "Actions",
      sortable: false,
      cellRenderer: DataGridActionBtns,
      cellRendererParams: {
        onViewClick: onEditClickHandler,
        onDeleteClick: onDeleteClickHandler,
        onSwitchClick: onSwitchClickHandler,
      },
      filter: false,
      pinned: "right",
    },
  ]);

  useEffect(() => {
    fetchTaskData();
  }, [projectData.projectId, userData.userId]);

  const fetchTaskData = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllTask +
          `CompanyId=${companyData.companyId}&UserId=${userData.userId}`
      );
      if (responseData.success) {
        setTaskRowData(responseData.data);
      } else {
        setTaskRowData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onSelectionChanged = () => {
    const selectedRows: any = gridRef.current?.api.getSelectedRows();
    const selectedData = selectedRows.map((item: any) => item.taskId);
    if (selectedData.length > 1) {
      setEnableDeleteAll(true);
    } else {
      setEnableDeleteAll(false);
    }
    setSelectedIds(selectedData.join(","));
  };
  const handleDeleteAllbtn = () => {
    setDeleteAllMessage("Are you sure to delete all the records?");
    setshowDeleteAllConfirm(true);
  };
  const handleDeleteAllCancel = () => {
    // Deselect all selected rows
    gridRef.current?.api.deselectAll();
    setSelectedIds("");
  };
  const handleDeleteAllCancelled = () => {
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllConfirm = () => {
    deleteTask(selectedIds, userData.userId);
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllAlert = () => {
    fetchTaskData();
    setShowDeleteAllAlert(false);
  };

  const activateDeactivateTask = async (taskId: any, UserId: any) => {
    // Call getDataById API
    try {
      const responseData = await acivateDeactivate(
        actDeactTask + `TaskId=${taskId}&UserId=${UserId}`
      );
      if (responseData.success) {
        setToggleMessage(responseData.message);
        setShowToggleAlert(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteTask = async (taskId: any, UserId: any) => {
    // Call delete API
    try {
      const responseData = await deleteData(
        deleteTaskData + `TaskId=${taskId}&UserId=${UserId}`
      );
      // if (responseData.success) {
      //     setDeleteMessage(responseData.message)
      //     setShowDeleteAlert(true);
      // }
      if (responseData.length > 0) {
        const combinedMessage: any = getCombinedMessage(responseData);
        setDeleteAllMessage(combinedMessage);
        setShowDeleteAllAlert(true);
      } else {
        if (responseData.success) {
          setDeleteMessage(responseData.message);
          setShowDeleteAlert(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCombinedMessage = (responseData: any[]) => {
    const msgDeleted = responseData
      .filter((item) => item.success)
      .map((item) => item.message);

    const msgNotDeleted = responseData
      .filter((item) => !item.success)
      .map((item) => item.message.replace(/\r\n/g, "\n")); // Replace \r\n with space

    return `
            ${
              msgDeleted.length > 0
                ? `Deleted Records:\n${msgDeleted.join("\n")}\n\n`
                : ""
            }
            ${
              msgNotDeleted.length > 0
                ? `Not Deleted Records:\n${msgNotDeleted.join("\n")}\n\n`
                : ""
            }
            `;
  };
  const gridOptions = {
    overlayNoRowsTemplate: '<span style="padding: 10px;">No Data Found</span>',
  };
  const handleBulkUploadBtn = () => {
    reRoute("/taskBulkUpload");
  };

  const onExportToExcel = () => {
    const columns = JSON.stringify(
      columnDefs
        .filter((x) => x.headerName !== "Actions")
        .map((column) => {
          if (column.headerName === "Sl.No") {
            return {
              label: column.headerName,
              value: "slNo",
            };
          } else if (column.headerName === "Status") {
            return {
              label: column.headerName,
              value: "activeFlag",
            };
          } else if (column.headerName === "Activity Name") {
            return {
              label: column.headerName,
              value: "activityName",
            };
          } else if (column.headerName === "UOM") {
            return {
              label: column.headerName,
              value: "uom",
            };
          } else {
            return {
              label: column.headerName,
              value: column.field,
            };
          }
        })
    );

    // Convert selectedIds from a comma-separated string to an array of numbers
    const selectedIdsArray = selectedIds
      ? selectedIds.split(",").map((id) => parseInt(id.trim(), 10))
      : [];

    const filteredData =
      selectedIdsArray.length > 0 && taskRowData !== undefined
        ? taskRowData.filter((row) => selectedIdsArray.includes(row.taskId))
        : taskRowData;

    let content = "{}";
    if (filteredData !== undefined) {
      let data = JSON.stringify(
        filteredData.map((row, index) => {
          return {
            slNo: index + 1,
            activityName: row.activity,
            taskCode: row.taskCode,
            taskName: row.taskName,
            uom: row.uom,
            planedStartDate:
              row.planedStartDate !== null
                ? row.planedStartDate.split("T")[0]
                : "",
            planedFinishDate:
              row.planedFinishDate !== null
                ? row.planedFinishDate.split("T")[0]
                : "",
            costCode: row.costCode,
            taktCode: row.taktCode,
            p6code: row.p6code,
            activeFlag: row.activeFlag === 0 ? "Inactive" : "Active",
            createdTime: row.createdTime.split(" ")[0],
          };
        })
      );

      content = data;
    }

    ExportToExcel("Task List", columns, content);
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <div className="d-flex gap-2 align-items-center">
                  {location.search.split("?")[1] === "adminDashboard" && (
                    <button
                      className="btn bg-light"
                      onClick={() => reRoute(-1)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  Manage Task
                </div>
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Task
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h6>Task List</h6>
            </div>
            <div className="d-flex justify-content-start gap-2">
              {enableDeleteAll ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary text-white"
                    onClick={handleDeleteAllCancel}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ marginRight: 5 }}
                    />
                    <span>Cancel</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleDeleteAllbtn}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ marginRight: 5 }}
                    />
                    <span>Delete All</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn custom-btn add-btn"
                    type="button"
                    onClick={handleAddBtn}
                    style={{ marginRight: 0 }}
                  >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                    <span>Add</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleBulkUploadBtn}
                  >
                    <span>Bulk Upload</span>
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn custom-btn"
                onClick={onExportToExcel}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ marginRight: 5 }}
                />
                <span>Export Excel</span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            {taskRowData && (
              <div className="ag-theme-quartz" style={{ height: 380 }}>
                <AgGridReact
                  rowData={taskRowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // autoSizeStrategy={{ type: 'fitCellContents' }}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={5}
                  paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                  tooltipShowDelay={500}
                  gridOptions={gridOptions}
                  onSelectionChanged={onSelectionChanged}
                  ref={gridRef}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="toggleConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleToggleCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleAlert ? "show" : ""} `}
        tabIndex={-1}
        id="toggleAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteAllCancelled}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
