// components/common/ConfirmationModal.tsx
import React from "react";

interface ConfirmationModalProps {
  show: boolean;
  message: string;
  image: string;
  onConfirm: () => void;
  onCancel?: () => void;
  showCancelButton?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  message,
  image,
  onConfirm,
  onCancel,
  showCancelButton = true,
}) => {
  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      tabIndex={-1}
      aria-labelledby="confirmationModal"
      aria-hidden="true"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <img
                src={image}
                alt="confirmation"
                className=""
                style={{ width: 150, height: 150 }}
              />
            </div>
            <div className="d-flex justify-content-center text-center mt-2">
              <p style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}>
                {message}
              </p>
            </div>
            <div className="d-flex justify-content-center">
              {showCancelButton && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              )}
              <button
                type="button"
                className="btn custom-btn"
                onClick={onConfirm}
                style={{ width: 183.5 }}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
