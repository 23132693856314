import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSave,
  faXmark,
  faBuildingFlag,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  getEngineerDrpDwn,
  getManagerDrpDwn,
  getProjectDataById,
  getStaffDrpDwn,
  saveProject,
} from "../../../services/Endpoints";
import { getData, getDataById, postData } from "../../../services/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
// import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import MultiSelect from "../../../components/common/MultiselectDropdown";
import { countries } from "../../../services/countries";
import Multiselect from "multiselect-react-dropdown";

export interface IEditProjectProps {}

interface IProjectData {
  projectId: number;
  companyId: number;
  projectCode: string;
  projectName: string;
  projectEmail: string;
  password: string;
  projectManager: number;
  projectEngineer: number;
  projectClient: string;
  projectContact: number;
  projectAddress: string;
  projectLocation: string;
  totalWorkHrsPerShift: number;
  staffIdCommaSeparated: string;
  userId: number;
}

interface IFormErrorData {
  projectName: string;
  projectCode: string;
  projectEmail: string;
  password: string;
  projectManager: string;
  projectEngineer: string;
  projectClient: string;
  projectContact: string;
  projectAddress: string;
  projectLocation: string;
  totalWorkHrsPerShift: string;
  staffIdCommaSeparated: string;
  countryname: string;
}

export function EditProject() {
  // Declarations //
  const { Id } = useParams();
  const reRoute = useNavigate();
  const { userData, companyData } = useAuth();

  const [managerDrpDwnData, setManagerDrpDwnData] = useState<any[]>([]);
  const [engineerDrpDwnData, setEngineerDrpDwnData] = useState<any[]>([]);
  const [staffDrpDwnData, setStaffDrpDwnData] = useState<any[]>([]);
  // const [selectedStaff, setSelectedStaff] = useState("");
  const [staffDefaultData, setStaffDefaultData] = useState<any[]>([]);

  const [formData, setFormData] = useState<IProjectData>({
    projectId: 0,
    companyId: companyData.companyId,
    projectCode: "",
    projectName: "",
    projectEmail: "",
    password: "Password",
    projectManager: 0,
    projectEngineer: 0,
    projectClient: "",
    projectContact: 0,
    projectAddress: "",
    projectLocation: "",
    totalWorkHrsPerShift: 10,
    staffIdCommaSeparated: "",
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    projectName: "",
    projectCode: "",
    projectEmail: "",
    password: "",
    projectManager: "",
    projectEngineer: "",
    projectClient: "",
    projectContact: "",
    projectAddress: "",
    projectLocation: "",
    totalWorkHrsPerShift: "",
    staffIdCommaSeparated: "",
    countryname: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  const [countryname, setCountryname] = useState<string>("");
  const [countrycode, setCountrycode] = useState<number>(0);

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchManagerDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getManagerDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setManagerDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchEngineerDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getEngineerDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setEngineerDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchStaffDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getStaffDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          // setStaffDrpDwnData(responseData.data);
          setStaffDrpDwnData([]);
          responseData.data.forEach((item: any) => {
            setStaffDrpDwnData((prevState) => [
              ...prevState,
              { value: item.value, label: item.text },
            ]);
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchProjectData = async () => {
      // Call getDataById API
      try {
        const responseData = await getDataById(getProjectDataById, Id);
        if (responseData.success) {
          var dat = responseData.data;
          var selectedValuesString: string = "";

          if (dat.selectedProjects !== null) {
            if (dat.selectedStaffs.length > 0) {
              setStaffDefaultData([]);
              dat.selectedStaffs.forEach((item: any) => {
                setStaffDefaultData((prevState) => [
                  ...prevState,
                  { value: item.value, label: item.text },
                ]);
              });

              selectedValuesString = dat.selectedStaffs
                .map((prj: any) => prj.value)
                .join(",");
            }
          }

          var extractedCountryCode = "";
          var extractedMobileNo = "";
          if (
            dat.projectContact.length > 10 &&
            dat.projectContact.startsWith("+")
          ) {
            const cntName: any = countries.find((option) =>
              dat.projectContact.startsWith(option.dial_code)
            );
            if (cntName) {
              setCountryname(`${cntName.name}(${cntName.dial_code})`);
              setCountrycode(cntName.dial_code);
              extractedCountryCode = cntName.dial_code;
              extractedMobileNo = dat.projectContact.replace(
                extractedCountryCode,
                ""
              );
            }
          }

          setFormData({
            projectId: dat.projectId,
            companyId: dat.companyId,
            projectCode: dat.projectCode,
            projectName: dat.projectName,
            projectEmail: dat.projectEmail,
            password: dat.decryptedPassword,
            projectManager: dat.projectManager,
            projectEngineer: dat.projectEngineer,
            projectClient: dat.projectClient,
            projectContact:
              extractedMobileNo === "" ? dat.projectContact : extractedMobileNo,
            projectAddress: dat.projectAddress,
            projectLocation: dat.projectLocation,
            totalWorkHrsPerShift: dat.totalWorkHrsPerShift,
            staffIdCommaSeparated: selectedValuesString,
            userId: userData.userId,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchManagerDrpDwn();
    fetchEngineerDrpDwn();
    fetchStaffDrpDwn();
    fetchProjectData();
  }, [Id, companyData.companyId, userData.userId]);

  const handleStaffSelect = (item: any) => {
    const selectedValuesString = item
      .map((staff: any) => staff.value)
      .join(",");

    setFormData(() => ({
      ...formData,
      staffIdCommaSeparated: selectedValuesString,
    }));

    setStaffDefaultData(item);
  };

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageProjects");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const spaceRegex = /^\s|\s$/;
    const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
    const decimalRegex = /^\d+(\.\d+)?$/;

    if (fieldName.includes("Code")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (
      fieldName.includes("Name") ||
      fieldName.toLowerCase().includes("password")
    ) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (
      fieldName.includes("Email") ||
      fieldName.includes("Client") ||
      fieldName.includes("Location")
    ) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 100) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("projectManager")) {
      if (fieldValue.length === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Project Manager is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("projectEngineer")) {
      if (fieldValue.length === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Project Engineer is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("projectContact")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "") {
        setFormError({
          ...formError,
          [fieldName]: "Empty spaces are not allowed.",
        });
        return true;
      } else if (
        fieldValue.length < 10 ||
        fieldValue.length > 10 ||
        !validateNumeric(fieldValue)
      ) {
        setFormError({ ...formError, [fieldName]: "Invalid Contact Number." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("projectAddress")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 500 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 500) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 500 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("countryname")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "") {
        setFormError({
          ...formError,
          [fieldName]: "Country Name is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("totalWorkHrsPerShift")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be of decimal only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!decimalRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]: "Must be of decimal only.",
        });
        return true;
      } else if (fieldValue > 12) {
        setFormError({
          ...formError,
          [fieldName]: "Must be of less than 12 hrs.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.projectName.trim() === "") {
      err.projectName = "Project Name is required.";
    } else {
      if (validatefields("projectName", formData.projectName)) {
        err.projectName =
          "Must be between 2 to 50 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (formData.projectCode.trim() === "") {
      err.projectCode = "Project Code is required.";
    } else {
      if (validatefields("projectCode", formData.projectCode)) {
        err.projectCode =
          "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (formData.projectEmail.trim() === "") {
      err.projectEmail = "Project Email is required.";
    } else if (!validateEmail(formData.projectEmail)) {
      err.projectEmail = "Invalid email format.";
    } else {
      if (validatefields("projectEmail", formData.projectEmail)) {
        err.projectEmail =
          "Must be between 2 and 50 characters, without empty spaces at the beginning.";
      }
    }

    if (
      formData.projectManager === 0 ||
      formData.projectManager.toString().length === 0
    ) {
      err.projectManager = "Project Manager is required.";
    }
    if (
      formData.projectEngineer === 0 ||
      formData.projectEngineer.toString().length === 0
    ) {
      err.projectEngineer = "Project Engineer is required.";
    }

    if (formData.projectClient.trim() === "") {
      err.projectClient = "Project Client is required.";
    } else {
      if (validatefields("projectClient", formData.projectClient)) {
        err.projectClient =
          "Must be between 2 and 50 characters, without empty spaces at the beginning.";
      }
    }

    if (countryname.trim() === "") {
      err.countryname = "Country Name is required.";
    }

    if (formData.projectContact === 0) {
      err.projectContact = "Project Contact is required.";
    } else if (validatefields("projectContact", formData.projectContact)) {
      err.projectContact = "Invalid Contact Number.";
    } else if (!validateNumeric(formData.projectContact)) {
      err.projectContact = "Invalid Contact Number.";
    }

    if (formData.projectAddress.trim() === "") {
      err.projectAddress = "Project Address is required.";
    } else {
      if (validatefields("projectAddress", formData.projectAddress)) {
        err.projectAddress =
          "Must be between 2 and 500 characters, without empty spaces at the beginning.";
      }
    }

    if (formData.projectLocation.trim() === "") {
      err.projectLocation = "Project Location is required.";
    } else {
      if (validatefields("projectLocation", formData.projectLocation)) {
        err.projectLocation =
          "Must be between 2 and 50 characters, without empty spaces at the beginning.";
      }
    }

    if (formData.staffIdCommaSeparated === "") {
      err.staffIdCommaSeparated = "Staff is required.";
    }

    if (formData.totalWorkHrsPerShift === 0) {
      err.totalWorkHrsPerShift = "Total Working Hrs Per Shift is required.";
    } else {
      if (
        validatefields("totalWorkHrsPerShift", formData.totalWorkHrsPerShift)
      ) {
        err.totalWorkHrsPerShift =
          "Must be of decimal only, without empty spaces.";
      }
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateNumeric = (entText: any) => {
    // Regular expression for numeric validation
    //const numRegex = /^[0-9]*$/;
    const numRegex = /^[0-9+ -]*$/;
    return numRegex.test(entText);
  };

  // const validatemaxLength = (entText: any) => {
  //   // Regular expression for max 10 digit validation
  //   const numRegex = /^\d{10}$/;
  //   return numRegex.test(entText);
  // };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);
      const payLoad = {
        projectId: formData.projectId,
        companyId: companyData.companyId,
        projectCode: formData.projectCode,
        projectName: formData.projectName,
        projectEmail: formData.projectEmail,
        password: formData.password,
        projectManager: formData.projectManager,
        projectEngineer: formData.projectEngineer,
        projectClient: formData.projectClient,
        projectContact:
          countrycode !== 0
            ? countrycode + formData.projectContact
            : formData.projectContact,
        projectAddress: formData.projectAddress,
        projectLocation: formData.projectLocation,
        totalWorkHrsPerShift: formData.totalWorkHrsPerShift,
        staffIdCommaSeparated: formData.staffIdCommaSeparated,
        userId: userData.userId,
      };
      // calling save api
      try {
        const responseData = await postData(saveProject, payLoad);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    // setFormData({
    //   projectId: 0,
    //   companyId: companyData.companyId,
    //   projectCode: "",
    //   projectName: "",
    //   projectEmail: "",
    //   projectManager: 0,
    //   projectEngineer: 0,
    //   projectClient: "",
    //   projectContact: 0,
    //   projectAddress: "",
    //   projectLocation: "",
    //   staffIdCommaSeparated: "",
    //   userId: userData.userId,
    // });

    // setFormError({
    //   projectName: "",
    //   projectCode: "",
    //   projectEmail: "",
    //   projectManager: "",
    //   projectEngineer: "",
    //   projectClient: "",
    //   projectContact: "",
    //   projectAddress: "",
    //   projectLocation: "",
    //   staffIdCommaSeparated: "",
    // });

    // setStaffDefaultData([]);
    reRoute("/manageProjects");
  };
  const options = engineerDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));

  const options1 = managerDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("projectEngineer", selectedOption.value);
      setFormData({
        ...formData,
        projectEngineer: selectedOption.value,
      });
    } else {
      validatefields("projectEngineer", ""); // or any other default value
      setFormData({
        ...formData,
        projectEngineer: 0, // Set to an empty string or any other default value
      });
    }
  };

  const onChangeHandler2 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("projectManager", selectedOption.value);
      setFormData({
        ...formData,
        projectManager: selectedOption.value,
      });
    } else {
      validatefields("projectManager", ""); // or any other default value
      setFormData({
        ...formData,
        projectManager: 0, // Set to an empty string or any other default value
      });
    }
  };

  const optCountry = countries.map((opt) => ({
    value: opt.dial_code,
    label: `${opt.name}(${opt.dial_code})`,
  }));

  const onCountryChangeHandler = (selectedOption: any, event: any) => {
    if (selectedOption) {
      validatefields(event.name, selectedOption.value);
      setCountrycode(selectedOption.value);
      setCountryname(selectedOption.label);
    } else {
      validatefields(event.name, "");
      setCountrycode(0);
      setCountryname("");
    }
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageProjects"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Edit Project
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageProjects">
                      <FontAwesomeIcon icon={faBuildingFlag} />
                      <span>Manage Project</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Project
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Project Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="projectCode"
                  value={formData.projectCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.projectCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Project Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="projectName"
                  value={formData.projectName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.projectName}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Project Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="projectEmail"
                  value={formData.projectEmail}
                  onChange={onChangeHandler}
                  disabled={true}
                />
                <span className="text-danger">{formError.projectEmail}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.password}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Project Manager <span className="text-danger">*</span>
                </label>
                {/* <select name="projectManager" className="form-select" value={formData.projectManager} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {managerDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="projectManager"
                  value={options1.find(
                    (option) => option.value === formData.projectManager
                  )}
                  onChange={onChangeHandler2}
                  options={options1}
                  placeholder="Select project Manager"
                />
                <span className="text-danger">{formError.projectManager}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Project Engineer <span className="text-danger">*</span>
                </label>
                <Select
                  name="projectEngineer"
                  value={options.find(
                    (option) => option.value === formData.projectEngineer
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select project Engineer"
                />
                <span className="text-danger">{formError.projectEngineer}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Project Staff <span className="text-danger">*</span>
                </label>
                <div
                  className="dropdown-wrapper"
                  style={{
                    height: "250px",
                    overflow: "auto",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                  }}
                >
                  <Multiselect
                    displayValue="label"
                    options={staffDrpDwnData}
                    selectedValues={staffDefaultData}
                    onSelect={(item: any) => handleStaffSelect(item)}
                    onRemove={(item: any) => handleStaffSelect(item)}
                    showCheckbox
                    style={{
                      chips: {
                        background: "#007bff",
                        color: "white",
                        fontSize: "12px",
                        margin: "2px",
                      },
                      optionContainer: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                      searchBox: {
                        border: "none",
                        borderBottom: "1px solid #ced4da",
                        borderRadius: "0",
                      },
                    }}
                  />
                </div>

                <span className="text-danger">
                  {formError.staffIdCommaSeparated}
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-8">
                <label className="form-label">
                  Project Address <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  rows={3}
                  name="projectAddress"
                  value={formData.projectAddress}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.projectAddress}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Project Location <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="projectLocation"
                  value={formData.projectLocation}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.projectLocation}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Country<span className="text-danger">*</span>
                </label>
                <Select
                  name="countryname"
                  value={optCountry.find(
                    (option) => option.label === countryname
                  )}
                  onChange={onCountryChangeHandler}
                  options={optCountry}
                  placeholder="Select Country"
                />
                <span className="text-danger">{formError.countryname}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Contact Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="projectContact"
                  value={
                    formData.projectContact === 0 ? "" : formData.projectContact
                  }
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.projectContact}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Client Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="projectClient"
                  value={formData.projectClient}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.projectClient}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Total Working Hrs Per Shift{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="totalWorkHrsPerShift"
                  value={formData.totalWorkHrsPerShift}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">
                  {formError.totalWorkHrsPerShift}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
