import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faHouse,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useEffect, useMemo, useRef } from "react";
import {
  actDeactTaskLevelMapping,
  deleteTaskLevelMapping,
  getAllTaskLevelList,
} from "../../../services/Endpoints";
import { acivateDeactivate, deleteData, getData } from "../../../services/API";
import { useAuth } from "../../../providers/AuthContext";
import { StatusBtn } from "../../../components/common/StatusBtns";
import { DataGridActionBtns } from "../../../components/common/DataGridActionBtns";
import activateImg from "../../../assets/activate.gif";
import deleteImg from "../../../assets/Delete.gif";
import { ExportToExcel } from "../../../services/ExportToFile";

export interface IManageTaskLevelMappingProps {}

export interface ITaskLevelData {
  taskLevelId: number;
  projectId: number;
  taskId: number;
  projectLevelId: number;
  quantity: number;
  skilledhours: number;
  unskilledhours: number;
  remark: string;
  uom: string;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  project: Project;
  projectLevel: ProjectLevel;
  task: Task;
}

export interface Project {
  projectId: number;
  companyId: number;
  userId: number;
  projectCode: string;
  projectName: string;
  projectEmail: string;
  projectManager: number;
  projectEngineer: number;
  projectClient: string;
  projectContact: number;
  projectAddress: string;
  projectLocation: string;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  company: any;
  projectEngineerDetail: any;
  projectManagerDetail: any;
  projectAssignMasters: any;
  selectedStaffs: any;
}

export interface ProjectLevel {
  projectLevelId: number;
  projectId: number;
  rootId: number;
  levelName: string;
  levelCode: string;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: any;
  project: any;
  srNo: number;
}

export interface Task {
  taskId: number;
  projectId: number;
  activityId: number;
  taskCode: string;
  taskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: number;
  uomId: number;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: any;
  uom: any;
  activity: any;
  project: any;
  taskSubTaskMappingMasters: any[];
  selectedSubTasks: any;
}

export function ManageTaskLevelMapping() {
  // Declarations //
  const reRoute = useNavigate();
  const { userData, projectData } = useAuth();

  const [taskLevelMapRowData, setTaskLevelMapRowData] =
    useState<ITaskLevelData[]>();
  const [selectedData, setSelectedData] = useState<any>({});

  const [showToggleConfirm, setShowToggleConfirm] = useState(false);
  const [showToggleAlert, setShowToggleAlert] = useState(false);
  const [toggleMessage, setToggleMessage] = useState("");

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const gridRef = useRef<AgGridReact<ITaskLevelData>>(null);
  const [selectedIds, setSelectedIds] = useState("");
  const [enableDeleteAll, setEnableDeleteAll] = useState(false);
  const [showDeleteAllConfirm, setshowDeleteAllConfirm] = useState(false);
  const [showDeleteAllAlert, setShowDeleteAllAlert] = useState(false);
  const [deleteAllMessage, setDeleteAllMessage] = useState("");

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchTaskLevelMappingData = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getAllTaskLevelList +
            `ProjectId=${projectData.projectId}&UserId=${userData.userId}`
        );
        if (responseData.success) {
          setTaskLevelMapRowData(responseData.data);
        } else {
          setTaskLevelMapRowData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTaskLevelMappingData();
  }, [projectData.projectId, userData.userId]);

  const fetchTaskLevelMappingData = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllTaskLevelList +
          `ProjectId=${projectData.projectId}&UserId=${userData.userId}`
      );
      if (responseData.success) {
        setTaskLevelMapRowData(responseData.data);
      } else {
        setTaskLevelMapRowData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddBtn = () => {
    reRoute("/addTaskLevelMapping");
  };

  const onEditClickHandler = (row: any) => {
    var id = row.taskLevelId;
    reRoute("/editTaskLevelMapping/" + id);
  };

  const onDeleteClickHandler = (row: any) => {
    setDeleteMessage("Are you sure to  Delete this record ?");
    setshowDeleteConfirm(true);
    setSelectedData({
      taskLevelId: row.taskLevelId,
      userId: userData.userId,
    });
  };

  const handleDeleteConfirm = () => {
    deleteTaskLvlMapping(selectedData.taskLevelId, selectedData.userId);
    closeDeleteConfirm();
  };

  const handleDeleteAlert = () => {
    fetchTaskLevelMappingData();
    closeDeleteAlert();
  };

  const closeDeleteConfirm = () => {
    setshowDeleteConfirm(false);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteCancel = () => {
    closeDeleteConfirm();
  };

  const onSwitchClickHandler = (row: any) => {
    setToggleMessage(
      `Are you sure to ${
        row.activeFlag === 0 ? "Activate" : "Deactivate"
      } this Task Level Mapping ?`
    );
    setShowToggleConfirm(true);
    setSelectedData({
      taskLevelId: row.taskLevelId,
      userId: userData.userId,
    });
  };

  const handleToggleConfirm = () => {
    activateDeactivateTaskLevelMapping(
      selectedData.taskLevelId,
      selectedData.userId
    );
    closeToggleConfirm();
  };

  const handleToggleAlert = () => {
    fetchTaskLevelMappingData();
    closeToggleAlert();
  };

  const closeToggleConfirm = () => {
    setShowToggleConfirm(false);
  };

  const closeToggleAlert = () => {
    setShowToggleAlert(false);
  };

  const handleToggleCancel = () => {
    closeToggleConfirm();
  };

  // Datagrid table data
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Task Name",
      field: "task.taskName",
      filter: true,
      tooltipField: "task.taskName",
      headerTooltip: "Task Name",
    },
    {
      headerName: "UOM",
      field: "uom",
      filter: true,
      tooltipField: "uom",
      headerTooltip: "UOM",
    },
    {
      headerName: "Level Name",
      field: "projectLevel.levelName",
      filter: true,
      tooltipField: "projectLevel.levelName",
      headerTooltip: "Level Name",
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
      tooltipField: "quantity",
      headerTooltip: "Quantity",
    },
    {
      headerName: "Skilled Hours",
      field: "skilledhours",
      filter: true,
      tooltipField: "skilledhours",
      headerTooltip: "Skilled Hours",
    },
    {
      headerName: "Unskilled Hours",
      field: "unskilledhours",
      filter: true,
      tooltipField: "unskilledhours",
      headerTooltip: "Unskilled Hours",
    },
    {
      headerName: "Remarks",
      field: "remark",
      filter: true,
      tooltipField: "remark",
      headerTooltip: "Remark",
    },
    {
      headerName: "Created On",
      field: "createdTime",
      filter: true,
      tooltipField: "createdTime",
      headerTooltip: "Created On",
    },
    {
      headerName: "Status",
      cellRenderer: StatusBtn,
      field: "activeFlag",
      filter: false,
      sortable: false,
      width: 150,
      pinned: "right",
    },
    {
      headerName: "Actions",
      sortable: false,
      cellRenderer: DataGridActionBtns,
      cellRendererParams: {
        onViewClick: onEditClickHandler,
        onDeleteClick: onDeleteClickHandler,
        onSwitchClick: onSwitchClickHandler,
      },
      filter: false,
      pinned: "right",
    },
  ]);
  const onSelectionChanged = () => {
    const selectedRows: any = gridRef.current?.api.getSelectedRows();
    const selectedData = selectedRows.map((item: any) => item.taskLevelId);
    if (selectedData.length > 1) {
      setEnableDeleteAll(true);
    } else {
      setEnableDeleteAll(false);
    }
    setSelectedIds(selectedData.join(","));
  };
  const handleDeleteAllbtn = () => {
    setDeleteAllMessage("Are you sure to delete all the records?");
    setshowDeleteAllConfirm(true);
  };
  const handleDeleteAllCancel = () => {
    // Deselect all selected rows
    gridRef.current?.api.deselectAll();
    setSelectedIds("");
  };
  const handleDeleteAllCancelled = () => {
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllConfirm = () => {
    deleteTaskLvlMapping(selectedIds, userData.userId);
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllAlert = () => {
    fetchTaskLevelMappingData();
    setShowDeleteAllAlert(false);
  };

  const activateDeactivateTaskLevelMapping = async (
    taskLevelId: any,
    UserId: any
  ) => {
    // Call getDataById API
    try {
      const responseData = await acivateDeactivate(
        actDeactTaskLevelMapping + `TaskLevelId=${taskLevelId}&UserId=${UserId}`
      );
      if (responseData.success) {
        setToggleMessage(responseData.message);
        setShowToggleAlert(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteTaskLvlMapping = async (taskLevelId: any, UserId: any) => {
    setIsDeleting(true);
    // Call delete API
    try {
      const responseData = await deleteData(
        deleteTaskLevelMapping + `TaskLevelId=${taskLevelId}&UserId=${UserId}`
      );

      if (responseData.length > 0) {
        const combinedMessage: any = getCombinedMessage(responseData);
        setDeleteAllMessage(combinedMessage);
        setShowDeleteAllAlert(true);
      } else {
        if (responseData.success) {
          setDeleteMessage(responseData.message);
          setShowDeleteAlert(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsDeleting(false);
  };
  const getCombinedMessage = (responseData: any[]) => {
    const msgDeleted = responseData
      .filter((item) => item.success)
      .map((item) => item.message);

    const msgNotDeleted = responseData
      .filter((item) => !item.success)
      .map((item) => item.message.replace(/\r\n/g, "\n")); // Replace \r\n with space

    return `
            ${
              msgDeleted.length > 0
                ? `Deleted Records:\n${msgDeleted.join("\n")}\n\n`
                : ""
            }
            ${
              msgNotDeleted.length > 0
                ? `Not Deleted Records:\n${msgNotDeleted.join("\n")}\n\n`
                : ""
            }
            `;
  };

  const gridOptions = {
    overlayNoRowsTemplate: '<span style="padding: 10px;">No Data Found</span>',
  };
  const handleBulkUploadBtn = () => {
    reRoute("/taskLevelMappingBulkUpload");
  };

  const onExportToExcel = () => {
    const columns = JSON.stringify(
      columnDefs
        .filter((x) => x.headerName !== "Actions")
        .map((column) => {
          if (column.headerName === "Sl.No") {
            return {
              label: column.headerName,
              value: "slNo",
            };
          } else if (column.headerName === "Status") {
            return {
              label: column.headerName,
              value: "activeFlag",
            };
          } else if (column.headerName === "Task Name") {
            return {
              label: column.headerName,
              value: "taskName",
            };
          } else if (column.headerName === "Level Name") {
            return {
              label: column.headerName,
              value: "levelName",
            };
          } else {
            return {
              label: column.headerName,
              value: column.field,
            };
          }
        })
    );

    // Convert selectedIds from a comma-separated string to an array of numbers
    const selectedIdsArray = selectedIds
      ? selectedIds.split(",").map((id) => parseInt(id.trim(), 10))
      : [];

    const filteredData =
      selectedIdsArray.length > 0 && taskLevelMapRowData !== undefined
        ? taskLevelMapRowData.filter((row) =>
            selectedIdsArray.includes(row.taskLevelId)
          )
        : taskLevelMapRowData;

    let content = "{}";
    if (filteredData !== undefined) {
      let data = JSON.stringify(
        filteredData.map((row, index) => {
          return {
            slNo: index + 1,
            taskName: row.task.taskName,
            uom: row.uom,
            levelName: row.projectLevel.levelName,
            quantity: row.quantity,
            skilledhours: row.skilledhours,
            unskilledhours: row.unskilledhours,
            remark: row.remark,
            activeFlag: row.activeFlag === 0 ? "Inactive" : "Active",
            createdTime: row.createdTime.split(" ")[0],
          };
        })
      );

      content = data;
    }

    ExportToExcel("Tasklevel Mapping List", columns, content);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Manage Task Level Mapping</h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Task Level Mapping
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h6>Task Level Mapping List</h6>
            </div>
            <div className="d-flex justify-content-start gap-2">
              {enableDeleteAll ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary text-white"
                    onClick={handleDeleteAllCancel}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ marginRight: 5 }}
                    />
                    <span>Cancel</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleDeleteAllbtn}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ marginRight: 5 }}
                    />
                    <span>Delete All</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn custom-btn add-btn"
                    type="button"
                    onClick={handleAddBtn}
                    style={{ marginRight: 0 }}
                  >
                    <span>Add</span>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleBulkUploadBtn}
                  >
                    <span>Bulk Upload</span>
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn custom-btn"
                onClick={onExportToExcel}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ marginRight: 5 }}
                />
                <span>Export Excel</span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            {taskLevelMapRowData && (
              <div className="ag-theme-quartz" style={{ height: 380 }}>
                <AgGridReact
                  rowData={taskLevelMapRowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // autoSizeStrategy={{ type: 'fitCellContents' }}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={5}
                  paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                  tooltipShowDelay={500}
                  gridOptions={gridOptions}
                  onSelectionChanged={onSelectionChanged}
                  ref={gridRef}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="toggleConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleToggleCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleAlert ? "show" : ""} `}
        tabIndex={-1}
        id="toggleAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteAllCancelled}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="text-center text-white">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="mt-2">Deleting...</div>
          </div>
        </div>
      )}
    </>
  );
}
