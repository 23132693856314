import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSave,
  faXmark,
  faMagnifyingGlass,
  faUpload,
  faUserGear,
  faArrowLeft,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  deleteStaffProfilePic,
  getDesignationDrpDwn,
  getStaffDataById,
  saveStaff,
} from "../../../services/Endpoints";
import { getData, getDataById, postData } from "../../../services/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
import defaultImg from "../../../assets/default-user-thumbnail.png";
import Select from "react-select";

export interface IEditStaffProps {}

interface IStaffData {
  staffId: number;
  companyId: number;
  staffCode: string;
  staffName: string;
  email: string;
  password: string;
  designationId: number;
  profileImagebase64: string;
  fileName: string;
  logedInUserId: number;
}

interface IFormErrorData {
  staffCode: string;
  staffName: string;
  email: string;
  password: string;
  designationId: string;
  profileImagebase64: string;
}

export function EditStaff() {
  // Declarations //
  const { Id } = useParams();
  const reRoute = useNavigate();
  const { userData, companyData } = useAuth();

  const [designationDrpDwnData, setDesignationDrpDwnData] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");

  const [formData, setFormData] = useState<IStaffData>({
    staffId: 0,
    companyId: companyData.companyId,
    staffCode: "",
    staffName: "",
    email: "",
    password: "Password",
    designationId: 0,
    profileImagebase64: "",
    fileName: "",
    logedInUserId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    staffCode: "",
    staffName: "",
    email: "",
    password: "",
    designationId: "",
    profileImagebase64: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchDesignationDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getDesignationDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setDesignationDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchStaffData = async () => {
      // Call getDataById API
      try {
        const responseData = await getDataById(getStaffDataById, Id);
        if (responseData.success) {
          var dat = responseData.data;
          setFormData({
            staffId: dat.staffId,
            companyId: dat.companyId,
            staffCode: dat.staffCode,
            staffName: dat.staffName,
            email: dat.user.email,
            password: dat.decryptedPassword,
            designationId: dat.designationId,
            profileImagebase64: "",
            fileName: "",
            logedInUserId: userData.userId,
          });
          setSelectedFile(dat.user.image);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDesignationDrpDwn();
    fetchStaffData();
  }, [Id, companyData.companyId, userData.userId]);

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const fetchStaffData = async () => {
    // Call getDataById API
    try {
      const responseData = await getDataById(getStaffDataById, Id);
      if (responseData.success) {
        var dat = responseData.data;
        setFormData({
          staffId: dat.staffId,
          companyId: dat.companyId,
          staffCode: dat.staffCode,
          staffName: dat.staffName,
          email: dat.user.email,
          password: dat.decryptedPassword,
          designationId: dat.designationId,
          profileImagebase64: "",
          fileName: "",
          logedInUserId: userData.userId,
        });
        setSelectedFile(dat.user.image);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageStaff");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const handleFileButtonClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png,.jpeg,.jpg"; // Specify accepted file types if needed
    fileInput.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        if (file.size > 200 * 1024) {
          // Alert the user if the file size exceeds 200kb
          setShowFailAlert(true);
          setAlertFailMessage("The image size should be less than 200Kb.");
          setSelectedFile("");
          return; // Exit function without further processing
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target?.result as string;
          setSelectedFile(base64String);
          setFormData((prevState) => ({
            ...prevState,
            profileImagebase64: base64String, // Update profileImagebase64 field with selected base64 string
            fileName: file.name,
          }));
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const textregex = /^[A-Za-z ]*$/;
    const spaceRegex = /^\s|\s$/;

    if (fieldName.includes("Code")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "") {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (
      fieldName.includes("Name") ||
      fieldName.toLowerCase().includes("password")
    ) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("email")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 100) {
        setFormError({
          ...formError,
          [fieldName]: "Must be between 2 and 100 characters",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.staffName.trim() === "") {
      err.staffName = "Staff Name is required.";
    } else {
      if (validatefields("staffName", formData.staffName)) {
        err.staffName =
          "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning";
      }
    }

    if (formData.password.trim() === "") {
      err.password = "Password is required.";
    } else {
      if (validatefields("password", formData.password)) {
        err.password =
          "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning";
      }
    }

    if (formData.staffCode.trim() === "") {
      err.staffCode = "Staff Code is required.";
    } else {
      if (validatefields("staffCode", formData.staffCode)) {
        err.staffCode =
          "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (formData.email.trim() === "") {
      err.email = "Email is required.";
    } else if (!validateEmail(formData.email)) {
      err.email = "Invalid email format.";
    } else {
      if (validatefields("email", formData.email)) {
        err.email =
          "Must be between 2 and 100 characters, without empty spaces at the beginning.";
      }
    }

    if (formData.designationId === 0) {
      err.designationId = "Designation is required.";
    }

    // if (formData.erpCade === 0) {
    //   err.erpCade = "ERP Code is required.";
    // } else if (!validateNumeric(formData.erpCade)) {
    //   err.erpCade = "Please enter numeric values.";
    // }

    // if (formData.profileImagebase64 === "") {
    //   err.profileImagebase64 = "Profile Pic is required.";
    // }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const validateNumeric = (entText: any) => {
  //   // Regular expression for email validation
  //   const numRegex = /^[0-9]*$/;
  //   return numRegex.test(entText);
  // };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      if (formData.fileName) {
      }

      // calling save api
      try {
        const responseData = await postData(saveStaff, formData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    // setFormData({
    //   staffId: 0,
    //   companyId: companyData.companyId,
    //   staffCode: "",
    //   staffName: "",
    //   email: "",
    //   designationId: 0,
    //   profileImagebase64: "",
    //   fileName: "",
    //   logedInUserId: userData.userId,
    // });

    // setFormError({
    //   staffCode: "",
    //   staffName: "",
    //   email: "",
    //   designationId: "",
    //   profileImagebase64: ""
    // });

    // setSelectedFile("");
    reRoute("/manageStaff");
  };

  const onDeleteProfile = async () => {
    // setSelectedFile("");
    try {
      const responseData = await getData(
        deleteStaffProfilePic +
          `StaffId=${formData.staffId}&UserId=${formData.logedInUserId}`
      );
      if (responseData.success) {
        //console.log(responseData.data);
        fetchStaffData();
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const options = designationDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("designationId", selectedOption.value);
      setFormData({
        ...formData,
        designationId: selectedOption.value,
      });
    } else {
      validatefields("designationId", ""); // or any other default value
      setFormData({
        ...formData,
        designationId: 0, // Set to an empty string or any other default value
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageStaff"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Edit Staff
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageStaff">
                      <FontAwesomeIcon icon={faUserGear} />
                      <span>Manage Staff</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Staff
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Staff Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="staffCode"
                  value={formData.staffCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.staffCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Staff Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="staffName"
                  value={formData.staffName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.staffName}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Designation <span className="text-danger">*</span>
                </label>
                {/* <select name="designationId" className="form-select" value={formData.designationId} onChange={onChangeHandler} >
                  <option>Select</option>
                  {designationDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="designationId"
                  value={options.find(
                    (option) => option.value === formData.designationId
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select Designation"
                />
                <span className="text-danger">{formError.designationId}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.email}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.password}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="upload-area">
                  <FontAwesomeIcon icon={faUpload} size="2xl" />
                  {/* <span className="upload-text mt-2">Click to upload or drag and drop</span> */}
                  <span className="upload-size-text mt-2">
                    Max. File Size: 30MB
                  </span>
                  <button
                    type="button"
                    className="btn custom-btn mt-2"
                    onClick={handleFileButtonClick}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ marginRight: 5 }}
                    />
                    Browse File
                  </button>
                </div>
                <span className="text-danger">
                  {formError.profileImagebase64}
                </span>
              </div>
              <div className="col-md-6">
                {/* {selectedFile && <img src={selectedFile} alt="profile-pic" style={{ width: 300, height: 170, borderRadius: 12 }} />} */}
                {selectedFile === "" ? (
                  <img
                    src={defaultImg}
                    alt="default-thumbnail"
                    style={{ width: 200, height: 150, borderRadius: 5 }}
                  />
                ) : (
                  <img
                    src={selectedFile}
                    alt="profile-pic"
                    style={{ width: 200, height: 150, borderRadius: 5 }}
                  />
                )}

                <button
                  type="button"
                  className="btn btn-sm btn-danger mb-1"
                  onClick={onDeleteProfile}
                  style={{ marginLeft: "10px", marginTop: 115 }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700 }}>
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700 }}>
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
