// components/common/PageLayout.tsx
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faHouse } from "@fortawesome/free-solid-svg-icons";

interface PageLayoutProps {
  title: string;
  breadcrumbItems: string[];
  showBackButton?: boolean;
  children: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  breadcrumbItems,
  showBackButton = false,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <div className="d-flex gap-2 align-items-center">
                  {showBackButton && (
                    <button
                      className="btn bg-light"
                      onClick={() => navigate(-1)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  {title}
                </div>
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  {breadcrumbItems.map((item, index) => (
                    <li
                      key={index}
                      className={`breadcrumb-item ${
                        index === breadcrumbItems.length - 1 ? "active" : ""
                      }`}
                      aria-current={
                        index === breadcrumbItems.length - 1
                          ? "page"
                          : undefined
                      }
                    >
                      {item}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  );
};
