import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  getActivityProjectMapping,
  getAllActivityDrpDwn,
  saveActivityProjectMapping,
} from "../../../services/Endpoints";
import { getData, postData } from "../../../services/API";
import { Link } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import activateImg from "../../../assets/activate.gif";
import { useAuth } from "../../../providers/AuthContext";
import Multiselect from "multiselect-react-dropdown";

export interface IEditActivityProps {}

interface IActivityData {
  projectId: number;
  userId: number;
  activityIds: string;
}

interface IFormErrorData {
  activityIds: string;
}

export function EditActivityProjectMapping() {
  // Declarations //
  const { userData, projectData, companyData } = useAuth();

  const [formData, setFormData] = useState<IActivityData>({
    projectId: projectData.projectId,
    activityIds: "",
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    activityIds: "",
  });

  const [activityDrpDwnData, setActivityDrpDwnData] = useState<any[]>([]);
  const [activityDefaultData, setActivityDefaultData] = useState<any[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions //
  useEffect(() => {
    fetchActivityDrpDwn();
    fetchActivityData();
  }, []);

  const fetchActivityDrpDwn = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllActivityDrpDwn + `CompanyId=${companyData.companyId}`
      );
      if (responseData.success) {
        setActivityDrpDwnData([]);
        responseData.data.forEach((item: any) => {
          setActivityDrpDwnData((prevState) => [
            ...prevState,
            { value: item.value, label: item.text },
          ]);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchActivityData = async () => {
    // Call getDataById API
    try {
      const responseData = await getData(
        getActivityProjectMapping + `ProjectId=${projectData.projectId}`
      );
      if (responseData.success) {
        var dat = responseData.data;

        var selectedValuesString: string = "";

        if (dat.selectedIds !== null) {
          if (dat.selectedIds.length > 0) {
            setActivityDefaultData([]);
            dat.selectedIds.forEach((item: any) => {
              setActivityDefaultData((prevState) => [
                ...prevState,
                { value: item.value, label: item.text },
              ]);
            });

            selectedValuesString = dat.selectedIds
              .map((data: any) => data.value)
              .join(",");
          }
        }

        setFormData({
          projectId: projectData.projectId,
          activityIds: selectedValuesString,
          userId: userData.userId,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleActivitySelect = (item: any) => {
    const selectedValuesString = item.map((dat: any) => dat.value).join(",");
    setFormData(() => ({
      ...formData,
      activityIds: selectedValuesString,
    }));

    setActivityDefaultData(item);

    setIsChanged(true);
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    fetchActivityDrpDwn();
    fetchActivityData();
    setIsChanged(false);
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const onSubmitHandler = () => {
    if (formData.activityIds !== "") {
      setIsSubmitting(true);
      saveActivityMapping();
    } else {
      setShowWarningAlert(true);
    }
  };

  const saveActivityMapping = async () => {
    setShowWarningAlert(false);
    if (!isSubmitting) {
      try {
        const responseData = await postData(
          saveActivityProjectMapping,
          formData
        );
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Activity Mapping</h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Activity Mapping
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3">
            <div className="row">
              <div className="col-md-8">
                <label className="form-label">
                  Activity <span className="text-danger">*</span>
                </label>
                <Multiselect
                  displayValue="label"
                  options={activityDrpDwnData}
                  selectedValues={activityDefaultData}
                  onSelect={(item: any) => handleActivitySelect(item)}
                  onRemove={(item: any) => handleActivitySelect(item)}
                  showCheckbox
                />
                <span className="text-danger">{formError.activityIds}</span>
              </div>
            </div>
            <div className="d-flex justify-content-start mt-3">
              <button
                type="button"
                className="btn custom-btn"
                disabled={!isChanged}
                hidden={isSubmitting}
                onClick={onSubmitHandler}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>{" "}
      <div
        className={`modal fade ${showWarningAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showWarningAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  Tasks will be deleted for selected Activity..!!
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={() => {
                    setIsSubmitting(true);
                    saveActivityMapping();
                  }}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
