// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-btn {
  /* width: 80px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    margin-right:5px; */
  margin: 0.25rem 0.125rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.active-btn {
  background-color: #00a73e;
  color: #ffffff;
}

.inactive-btn {
  background-color: #f05252;
  color: #ffffff;
}

.fixed-height-dropdown {
  max-height: 250px; /* Set the fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
}
`, "",{"version":3,"sources":["webpack://./src/styles/StatusBtn.css"],"names":[],"mappings":"AAAA;EACE;;;;uBAIqB;EACrB,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB,EAAE,yBAAyB;EAC5C,gBAAgB,EAAE,8BAA8B;AAClD","sourcesContent":[".status-btn {\r\n  /* width: 80px;\r\n    border-radius: 8px;\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n    margin-right:5px; */\r\n  margin: 0.25rem 0.125rem;\r\n  padding: 0.25rem 0.5rem;\r\n  font-size: 0.875rem;\r\n  border-radius: 0.2rem;\r\n}\r\n\r\n.active-btn {\r\n  background-color: #00a73e;\r\n  color: #ffffff;\r\n}\r\n\r\n.inactive-btn {\r\n  background-color: #f05252;\r\n  color: #ffffff;\r\n}\r\n\r\n.fixed-height-dropdown {\r\n  max-height: 250px; /* Set the fixed height */\r\n  overflow-y: auto; /* Enable vertical scrolling */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
