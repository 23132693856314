import "../../styles/StatusBtn.css";
export interface IStatusBtnProps {}

export function bulkUploadStatusBtn(props: any) {
  return (
    <>
      {props.colDef.headerName === "Upload Status" ? (
        <label
          className={`${
            props.data.success === "Success"
              ? "btn btn-sm btn-success mb-1"
              : props.data.success === "Failed"
              ? "btn btn-sm btn-danger mb-1"
              : props.data.success == "Updated"
              ? "btn btn-sm btn-warning mb-1"
              : ""
          }`}
          style={{ width: "90px" }}
        >
          {props.data.success}
        </label>
      ) : (
        <div style={{ width: 20 }}>
          <label
            className="btn btn-sm btn-success mb-1"
            hidden={!props.data.success}
          >
            Uploaded
          </label>
          <label
            className="btn btn-sm btn-danger mb-1"
            hidden={props.data.success}
          >
            Failed
          </label>
        </div>
      )}
    </>
  );
}
