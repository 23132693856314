import { Routes, Route } from "react-router-dom";
import { AdminDashboard } from "../pages/Admin/Dashboard/AdminDashboard";
import { ManageStaff } from "../pages/Admin/ManageStaff/ManageStaff";
import { ManageMachinery } from "../pages/Admin/ManageMachinery/ManageMachinery";
import { AddMachinery } from "../pages/Admin/ManageMachinery/AddMachinery";
import { ManageDesignations } from "../pages/Admin/ManageDesignations/ManageDesignations";
import { AddDesignations } from "../pages/Admin/ManageDesignations/AddDesignations";
import { EditDesignations } from "../pages/Admin/ManageDesignations/EditDesignations";
import { EditMachinery } from "../pages/Admin/ManageMachinery/EditMachinery";
import { ManageUom } from "../pages/Admin/ManageUOM/ManageUom";
import { AddUom } from "../pages/Admin/ManageUOM/AddUom";
import { EditUom } from "../pages/Admin/ManageUOM/EditUom";
import { ManageMaterial } from "../pages/Admin/ManageMaterial/ManageMaterial";
import { AddMaterial } from "../pages/Admin/ManageMaterial/AddMaterial";
import { EditMaterial } from "../pages/Admin/ManageMaterial/EditMaterial";
import { ManageInjury } from "../pages/Admin/ManageInjury/ManageInjury";
import { AddInjury } from "../pages/Admin/ManageInjury/AddInjury";
import { EditInjury } from "../pages/Admin/ManageInjury/EditInjury";
import { ManageShift } from "../pages/Admin/ManageShift/ManageShift";
import { AddShift } from "../pages/Admin/ManageShift/AddShift";
import { EditShift } from "../pages/Admin/ManageShift/EditShift";
import { ManageCompany } from "../pages/Admin/ManageCompany/ManageCompany";
import { AddCompany } from "../pages/Admin/ManageCompany/AddCompany";
import { EditCompany } from "../pages/Admin/ManageCompany/EditCompany";
import { ManageLabourType } from "../pages/Admin/ManageLabourType/ManageLabourType";
import { AddLabourType } from "../pages/Admin/ManageLabourType/AddLabourType";
import { EditLabourType } from "../pages/Admin/ManageLabourType/EditLabourType";
import { ManageSubContractor } from "../pages/Admin/ManageSubContractor/ManageSubContractor";
import { AddSubContractor } from "../pages/Admin/ManageSubContractor/AddSubContractor";
import { EditSubContractor } from "../pages/Admin/ManageSubContractor/EditSubContractor";
import { AddStaff } from "../pages/Admin/ManageStaff/AddStaff";
import { EditStaff } from "../pages/Admin/ManageStaff/EditStaff";
import { StaffBulkUpload } from "../pages/Admin/ManageStaff/StaffBulkUpload";
import { ManageProject } from "../pages/Admin/ManageProject/ManageProject";
import { AddProject } from "../pages/Admin/ManageProject/AddProject";
import { EditProject } from "../pages/Admin/ManageProject/EditProject";
import { ManageManPower } from "../pages/Admin/ManageManPower/ManageManPower";
import { EditManPower } from "../pages/Admin/ManageManPower/EditManPower";
import { AddManPower } from "../pages/Admin/ManageManPower/AddManPower";
import { ManPowerBulkUpload } from "../pages/Admin/ManageManPower/ManPowerBulkUpload";
import { ManageProjectLevel } from "../pages/Admin/ManageProjectLevel/ManageProjectLevel";
import { AddProjectLevel } from "../pages/Admin/ManageProjectLevel/AddProjectLevel";
import { EditProjectLevel } from "../pages/Admin/ManageProjectLevel/EditProjectLevel";
import { ManageWeather } from "../pages/Admin/ManageWeather/ManageWeather";
import { AddWeather } from "../pages/Admin/ManageWeather/AddWeather";
import { EditWeather } from "../pages/Admin/ManageWeather/EditWeather";
import { SubContractorBulkUpload } from "../pages/Admin/ManageSubContractor/SubContractorBulkUpload";
import { MaterialBulkUpload } from "../pages/Admin/ManageMaterial/MaterialBulkUpload";
import { MachineryBulkUpload } from "../pages/Admin/ManageMachinery/MachineryBulkUpload";
import { ManageActivity } from "../pages/Admin/ManageActivity/ManageActivity";
import { AddActivity } from "../pages/Admin/ManageActivity/AddActivity";
import { EditActivity } from "../pages/Admin/ManageActivity/EditActivity";
import { ManageSubTask } from "../pages/Admin/ManageSubTask/ManageSubTask";
import { AddSubTask } from "../pages/Admin/ManageSubTask/AddSubTask";
import { EditSubTask } from "../pages/Admin/ManageSubTask/EditSubTask";
import { ManageTask } from "../pages/Admin/ManageTask/ManageTask";
import { AddTask } from "../pages/Admin/ManageTask/AddTask";
import { EditTask } from "../pages/Admin/ManageTask/EditTask";
import { ManageTaskLevelMapping } from "../pages/ProjectAdmin/ManageTaskLevelMapping/ManageTaskLevelMapping";
import { AddTaskLevelMapping } from "../pages/ProjectAdmin/ManageTaskLevelMapping/AddTaskLevelMapping";
import { EditTaskLevelMapping } from "../pages/ProjectAdmin/ManageTaskLevelMapping/EditTaskLevelMapping";
import { SuperAdminDashboard } from "../pages/SuperAdmin/Dashboard/SuperAdminDashboard";
import { ProjectAdminDashboard } from "../pages/ProjectAdmin/Dashboard/ProjectAdminDashboard";
import { DesignationBulkUpload } from "../pages/Admin/ManageDesignations/DesignationBulkUpload";
import { ActivityBulkUpload } from "../pages/Admin/ManageActivity/ActivityBulkUpload";
import { SubTaskBulkUpload } from "../pages/Admin/ManageSubTask/SubTaskBulkUpload";
import { TaskBulkUpload } from "../pages/Admin/ManageTask/TaskBulkUpload";
import { UomBulkUpload } from "../pages/Admin/ManageUOM/UomBulkUpload";
import { ProjectBulkUpload } from "../pages/Admin/ManageProject/ProjectBulkUpload";
import { TaskLevelMappingBulkUpload } from "../pages/ProjectAdmin/ManageTaskLevelMapping/TaskLevelMappingBulkUpload";
import { DailyActivityReport } from "../pages/Admin/Reports/DailyActivityReport";
import { PrivacyPolicy } from "../pages/PrivacyPolicy/PrivacyPolicy";
import { AddActivityType } from "../pages/Admin/ManageActivityType/AddActivityType";
import { EditActivityType } from "../pages/Admin/ManageActivityType/EditActivityType";
import { ManageActivityType } from "../pages/Admin/ManageActivityType/ManageActivityType";
import { EditActivityProjectMapping } from "../pages/Admin/ManageActivity/EditActivityProjectMapping";
import { EditTaskProjectMapping } from "../pages/Admin/ManageTask/EditTaskProjectMapping";
import { EditSubTaskProjectMapping } from "../pages/Admin/ManageSubTask/EditSubTaskProjectMapping";

export interface IRoutesNamesProps {}

export function RoutesNames() {
  return (
    <Routes>
      {/* Users */}
      <Route path="/adminDashboard" Component={AdminDashboard} />
      <Route path="/superAdminDashboard" Component={SuperAdminDashboard} />
      <Route path="/projectAdminDashboard" Component={ProjectAdminDashboard} />

      {/* Manage Designation */}
      <Route path="/manageDesignations" Component={ManageDesignations} />
      <Route path="/addDesignations" Component={AddDesignations} />
      <Route path="/editDesignations/:Id" Component={EditDesignations} />
      <Route path="/designationBulkUpload" Component={DesignationBulkUpload} />

      {/* Manage Machinery */}
      <Route path="/manageMachinery" Component={ManageMachinery} />
      <Route path="/addMachinery" Component={AddMachinery} />
      <Route path="/machineryBulkUpload" Component={MachineryBulkUpload} />
      <Route path="/editMachinery/:Id" Component={EditMachinery} />

      {/* Manage UOM */}
      <Route path="/manageUom" Component={ManageUom} />
      <Route path="/addUom" Component={AddUom} />
      <Route path="/editUom/:Id" Component={EditUom} />
      <Route path="/uomBulkUpload" Component={UomBulkUpload} />

      {/* Manage Material */}
      <Route path="/manageMaterial" Component={ManageMaterial} />
      <Route path="/addMaterial" Component={AddMaterial} />
      <Route path="/materialBulkUpload" Component={MaterialBulkUpload} />
      <Route path="/editMaterial/:Id" Component={EditMaterial} />

      {/* Manage Injury */}
      <Route path="/manageInjury" Component={ManageInjury} />
      <Route path="/addInjury" Component={AddInjury} />
      <Route path="/editInjury/:Id" Component={EditInjury} />

      {/* Manage Shift */}
      <Route path="/manageShift" Component={ManageShift} />
      <Route path="/addShift" Component={AddShift} />
      <Route path="/editShift/:Id" Component={EditShift} />

      {/* Manage Company */}
      <Route path="/manageCompany" Component={ManageCompany} />
      <Route path="/addCompany" Component={AddCompany} />
      <Route path="/editCompany/:Id" Component={EditCompany} />

      {/* Manage Labour Type */}
      <Route path="/manageLabourType" Component={ManageLabourType} />
      <Route path="/addLabourType" Component={AddLabourType} />
      <Route path="/editLabourType/:Id" Component={EditLabourType} />

      {/* Manage Sub Contractor */}
      <Route path="/manageSubContractor" Component={ManageSubContractor} />
      <Route path="/addSubContractor" Component={AddSubContractor} />
      <Route
        path="/SubContractorBulkUpload"
        Component={SubContractorBulkUpload}
      />
      <Route path="/editSubContractor/:Id" Component={EditSubContractor} />

      {/* Manage Staff */}
      <Route path="/manageStaff" Component={ManageStaff} />
      <Route path="/addStaff" Component={AddStaff} />
      <Route path="/staffBulkUpload" Component={StaffBulkUpload} />
      <Route path="/editStaff/:Id" Component={EditStaff} />

      {/* Manage Project */}
      <Route path="/manageProjects" Component={ManageProject} />
      <Route path="/addProject" Component={AddProject} />
      <Route path="/editProject/:Id" Component={EditProject} />
      <Route path="/projectBulkUpload" Component={ProjectBulkUpload} />

      {/* Manage Manpower */}
      <Route path="/manageManPower" Component={ManageManPower} />
      <Route path="/addManPower" Component={AddManPower} />
      <Route path="/manpowerBulkUpload" Component={ManPowerBulkUpload} />
      <Route path="/editManPower/:Id" Component={EditManPower} />

      {/* Manage ProjectLevel */}
      <Route path="/manageProjectLevel" Component={ManageProjectLevel} />
      <Route path="/addProjectLevel" Component={AddProjectLevel} />
      <Route path="/editProjectLevel/:Id" Component={EditProjectLevel} />

      {/* Manage Weather */}
      <Route path="/manageWeather" Component={ManageWeather} />
      <Route path="/addWeather" Component={AddWeather} />
      <Route path="/editWeather/:Id" Component={EditWeather} />

      {/* Manage Activity */}
      <Route path="/manageActivity" Component={ManageActivity} />
      <Route path="/addActivity" Component={AddActivity} />
      <Route path="/editActivity/:Id" Component={EditActivity} />
      <Route path="/activityBulkUpload" Component={ActivityBulkUpload} />
      <Route
        path="/activityProjectMapping"
        Component={EditActivityProjectMapping}
      />

      {/* Manage SubTask */}
      <Route path="/manageSubTask" Component={ManageSubTask} />
      <Route path="/addSubTask" Component={AddSubTask} />
      <Route path="/editSubTask/:Id" Component={EditSubTask} />
      <Route path="/subtaskBulkUpload" Component={SubTaskBulkUpload} />
      <Route
        path="/subtaskProjectMapping"
        Component={EditSubTaskProjectMapping}
      />

      {/* Manage Task */}
      <Route path="/manageTask" Component={ManageTask} />
      <Route path="/addTask" Component={AddTask} />
      <Route path="/editTask/:Id" Component={EditTask} />
      <Route path="/taskBulkUpload" Component={TaskBulkUpload} />
      <Route path="/taskProjectMapping" Component={EditTaskProjectMapping} />

      {/* Manage Task Level Mapping */}
      <Route
        path="/manageTaskLevelMapping"
        Component={ManageTaskLevelMapping}
      />
      <Route path="/addTaskLevelMapping" Component={AddTaskLevelMapping} />
      <Route
        path="/editTaskLevelMapping/:Id"
        Component={EditTaskLevelMapping}
      />
      <Route
        path="/taskLevelMappingBulkUpload"
        Component={TaskLevelMappingBulkUpload}
      />

      <Route path="/dailyActivityReport" Component={DailyActivityReport} />

      <Route path="/privacyPolicy" Component={PrivacyPolicy} />

      {/* Manage Activity Type */}
      <Route path="/manageActivityType" Component={ManageActivityType} />
      <Route path="/addActivityType" Component={AddActivityType} />
      <Route path="/editActivityType/:Id" Component={EditActivityType} />
    </Routes>
  );
}
