// components/common/DataTableWrapper.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import DataTable from "./DataTable";

interface DataTableWrapperProps<T> {
  title: string;
  data: T[];
  columns: ColumnDef<T>[];
  totalRows: number;
  isLoading: boolean;
  isExporting: boolean;
  onAddClick?: () => void;
  onBulkUploadClick?: () => void;
  onExportClick?: () => void;
  fetchData: (
    page: number,
    pageSize: number,
    sorting: any,
    filters: any
  ) => Promise<void>;
  showAddButton?: boolean;
  showBulkUpload?: boolean;
  showExport?: boolean;
}

export function DataTableWrapper<T>({
  title,
  data,
  columns,
  totalRows,
  isLoading,
  isExporting,
  onAddClick,
  onBulkUploadClick,
  onExportClick,
  fetchData,
  showAddButton = true,
  showBulkUpload = true,
  showExport = true,
}: DataTableWrapperProps<T>) {
  return (
    <div className="card mt-3">
      <div className="d-flex justify-content-between">
        <div>
          <h6 style={{ paddingLeft: 20, paddingTop: 10 }}>{title}</h6>
        </div>
        <div
          className="d-flex justify-content-start gap-2"
          style={{ paddingRight: 20, paddingTop: 10 }}
        >
          {showAddButton && (
            <button
              className="btn custom-btn add-btn"
              type="button"
              onClick={onAddClick}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
              <span>Add</span>
            </button>
          )}
          {showBulkUpload && (
            <button
              className="btn custom-btn"
              type="button"
              onClick={onBulkUploadClick}
            >
              <span>Bulk Upload</span>
            </button>
          )}
          {showExport && (
            <button
              type="button"
              className="btn custom-btn"
              onClick={onExportClick}
              disabled={isExporting}
            >
              {isExporting ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Exporting...{" "}
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    style={{ marginRight: 5 }}
                  />
                  <span>Export Excel</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="card-body ag-theme-quartz">
        <DataTable
          data={data}
          columns={columns}
          totalRows={totalRows}
          isLoading={isLoading}
          fetchData={fetchData}
        />
      </div>
    </div>
  );
}
