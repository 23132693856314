import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSave,
  faXmark,
  faPersonDigging,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  getChargehandInchargeDrpDwn,
  getDesignationDropdownList,
  getEngineerInchargeDrpDwn,
  getForemanInchargeDrpDwn,
  getGangLeaderInchargeDrpDwn,
  getLabourSupplyDrpDwn,
  getManpowerDataById,
  getProjectDrpDwn,
  saveManpower,
} from "../../../services/Endpoints";
import { getData, getDataById, postData } from "../../../services/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
// import Select from 'react-select';
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

export interface IEditManPowerProps {}

interface IManpowerData {
  manpowerId: number;
  companyId: number;
  manpowerCode: string;
  manpowerName: string;
  designationId: number;
  engineerInchargeId: number;
  formanInchargeId: number;
  chargehandInchargeId: number;
  gangLeaderInchargeId: number;
  skillUnskill: string;
  subContructorId: number;
  performanceRating: number;
  projectIdCommaSeperated: string;
  userId: number;
}

interface IFormErrorData {
  manpowerCode: string;
  manpowerName: string;
  designationId: string;
  engineerInchargeId: string;
  formanInchargeId: string;
  chargehandInchargeId: string;
  gangLeaderInchargeId: string;
  skillUnskill: string;
  performanceRating: string;
  subContructorId: string;
  projectIdCommaSeperated: string;
}

// interface IDrpDwnData {
//   value: string,
//   label: string
// }

export function EditManPower() {
  // Declarations //
  const { Id } = useParams();
  const reRoute = useNavigate();
  const { userData, companyData } = useAuth();

  const [manPowerTradeDrpDwnData, setManPowerTradeDrpDwnData] = useState<any[]>(
    []
  );
  const [engineerInchargeDrpDwnData, setEngineerInchargeDrpDwnData] = useState<
    any[]
  >([]);
  const [foremanInchargeDrpDwnData, setForemanInchargeDrpDwnData] = useState<
    any[]
  >([]);
  const [chargehandInchargeDrpDwnData, setChargehandInchargeDrpDwnData] =
    useState<any[]>([]);
  const [gangLeaderInchargeDrpDwnData, setGangLeaderInchargeDrpDwnData] =
    useState<any[]>([]);
  const [labourSupplyDrpDwnData, setLabourSupplyDrpDwnData] = useState<any[]>(
    []
  );
  const [projectDrpDwnData, setProjectDrpDwnData] = useState<any[]>([]);
  const [projectDefaultData, setProjectDefaultData] = useState<any[]>([]);

  const [formData, setFormData] = useState<IManpowerData>({
    manpowerId: 0,
    companyId: companyData.companyId,
    manpowerCode: "",
    manpowerName: "",
    designationId: 0,
    engineerInchargeId: 0,
    formanInchargeId: 0,
    chargehandInchargeId: 0,
    gangLeaderInchargeId: 0,
    skillUnskill: "",
    subContructorId: 0,
    performanceRating: 0,
    projectIdCommaSeperated: "",
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    manpowerCode: "",
    manpowerName: "",
    designationId: "",
    engineerInchargeId: "",
    formanInchargeId: "",
    chargehandInchargeId: "",
    gangLeaderInchargeId: "",
    skillUnskill: "",
    performanceRating: "",
    subContructorId: "",
    projectIdCommaSeperated: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchManPowerTradeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getDesignationDropdownList + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          //console.log(responseData.data);
          setManPowerTradeDrpDwnData(responseData.data);
        } else {
          setManPowerTradeDrpDwnData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchEngineerInchargeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getEngineerInchargeDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setEngineerInchargeDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchForemanInchargeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getForemanInchargeDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setForemanInchargeDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchChargehandInchargeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getChargehandInchargeDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setChargehandInchargeDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchGangLeaderInchargeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getGangLeaderInchargeDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setGangLeaderInchargeDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchLabourSupplyDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getLabourSupplyDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setLabourSupplyDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchProjectDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getProjectDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setProjectDrpDwnData([]);
          responseData.data.forEach((item: any) => {
            setProjectDrpDwnData((prevState) => [
              ...prevState,
              { value: item.value, label: item.text },
            ]);
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchManpowerData = async () => {
      // Call getDataById API
      try {
        const responseData = await getDataById(getManpowerDataById, Id);
        if (responseData.success) {
          var dat = responseData.data;
          var selectedValuesString: string = "";

          if (dat.selectedProjects !== null) {
            if (dat.selectedProjects.length > 0) {
              setProjectDefaultData([]);
              dat.selectedProjects.forEach((item: any) => {
                setProjectDefaultData((prevState) => [
                  ...prevState,
                  { value: item.value, label: item.text },
                ]);
              });

              selectedValuesString = dat.selectedProjects
                .map((prj: any) => prj.value)
                .join(",");
            }
          }

          setFormData({
            manpowerId: dat.manpowerId,
            companyId: dat.companyId,
            manpowerCode: dat.manpowerCode,
            manpowerName: dat.manpowerName,
            designationId: dat.designationId,
            engineerInchargeId: dat.engineerInchargeId,
            formanInchargeId: dat.formanInchargeId,
            chargehandInchargeId: dat.chargehandInchargeId,
            gangLeaderInchargeId: dat.gangLeaderInchargeId,
            skillUnskill: dat.skillUnskill,
            subContructorId: dat.subContructorId,
            performanceRating: dat.performanceRating,
            projectIdCommaSeperated: selectedValuesString,
            userId: userData.userId,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchManPowerTradeDrpDwn();
    fetchEngineerInchargeDrpDwn();
    fetchForemanInchargeDrpDwn();
    fetchChargehandInchargeDrpDwn();
    fetchGangLeaderInchargeDrpDwn();
    fetchLabourSupplyDrpDwn();
    fetchProjectDrpDwn();
    fetchManpowerData();
  }, [Id, companyData.companyId, userData.userId]);

  // const setDefaultValues = async (dat: any) => {
  //   const temp: any[] = [];
  //   // Call getData API
  //   try {
  //     const responseData = await getData(getProjectDrpDwn + `CompanyId=${companyData.companyId}`);
  //     if (responseData.success) {
  //       setProjectDrpDwnData([]);
  //       responseData.data.forEach((item: any) => {
  //         setProjectDrpDwnData((prevState) => [
  //           ...prevState,
  //           { value: item.value, label: item.text }
  //         ]);

  //         var exist = temp.find(item.value);
  //         if (!exist) {
  //           temp.push({ value: item.value, label: item.text });
  //         }
  //         console.log(temp);
  //       });

  //       setProjectDefaultData([]);

  //       if (dat.manpowerProjectMappingMasters.length > 0) {
  //         const defaultProjects = dat.manpowerProjectMappingMasters.map((item: any) => {
  //           return temp.find((project: any) => project.value === String(item.projectId));
  //         });

  //         setProjectDefaultData((prevState) => [
  //           ...prevState,
  //           defaultProjects
  //         ]);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }

  // };

  const handleProjectSelect = (item: any) => {
    const selectedValuesString = item.map((prj: any) => prj.value).join(",");
    setFormData(() => ({
      ...formData,
      projectIdCommaSeperated: selectedValuesString,
    }));

    setProjectDefaultData(item);
  };

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageManPower");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const spaceRegex = /^\s|\s$/;
    const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
    if (fieldName.includes("Code")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("Name")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("Rating")) {
      if (fieldValue === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue <= 0 || fieldValue > 100) {
        setFormError({
          ...formError,
          [fieldName]: "Value must be between 1 to 100",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("skill")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 100) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("engineerInchargeId")) {
      if (fieldValue.length === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Engineer Incharge is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("formanInchargeId")) {
      if (fieldValue.length === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Foreman Incharge is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
    // else if (fieldName.includes('chargehandInchargeId')) {
    //   if (fieldValue.length === 0) {
    //     setFormError({ ...formError, [fieldName]: 'Chargehand Incharge is required.' });
    //     return true;
    //   } else {
    //     setFormError({ ...formError, [fieldName]: '' });
    //     return false;
    //   }
    // } else if (fieldName.includes('gangLeaderInchargeId')) {
    //   if (fieldValue.length === 0) {
    //     setFormError({ ...formError, [fieldName]: 'Gangleader Incharge is required.' });
    //     return true;
    //   } else {
    //     setFormError({ ...formError, [fieldName]: '' });
    //     return false;
    //   }
    // }
    // else if (fieldName.includes('subContructorId')) {
    //   if (fieldValue.length === 0) {
    //     setFormError({ ...formError, [fieldName]: 'Labour Supply is required.' });
    //     return true;
    //   } else {
    //     setFormError({ ...formError, [fieldName]: '' });
    //     return false;
    //   }
    // }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.manpowerCode.trim() === "") {
      err.manpowerCode = "Manpower Code is required.";
    } else {
      if (validatefields("manpowerCode", formData.manpowerCode)) {
        err.manpowerCode =
          "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (formData.manpowerName.trim() === "") {
      err.manpowerName = "Manpower Name is required.";
    } else {
      if (validatefields("manpowerName", formData.manpowerName)) {
        err.manpowerName =
          "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.";
      }
    }

    if (formData.designationId === 0) {
      err.designationId = "Manpower Trade is required.";
    }

    if (
      formData.engineerInchargeId === 0 ||
      formData.engineerInchargeId.toString().length === 0
    ) {
      err.engineerInchargeId = "Engineer Incharge is required.";
    }
    if (
      formData.formanInchargeId === 0 ||
      formData.formanInchargeId.toString().length === 0
    ) {
      err.formanInchargeId = "Foreman Incharge is required.";
    }

    // if (formData.chargehandInchargeId === 0 || formData.chargehandInchargeId.toString().length === 0) {
    //   err.chargehandInchargeId = "Chargehand Incharge is required.";
    // }
    // if (formData.gangLeaderInchargeId === 0 || formData.gangLeaderInchargeId.toString().length === 0) {
    //   err.gangLeaderInchargeId = "Gangleader Incharge is required.";
    // }

    if (formData.skillUnskill.trim() === null) {
      err.skillUnskill = "Skill/unskill can not be null.";
    } else {
      if (validatefields("skillUnskill", formData.skillUnskill)) {
        err.skillUnskill =
          "Must be between 2 and 50 characters, without empty spaces at the beginning.";
      }
    }

    // if (formData.performanceRating === 0) {
    //   err.performanceRating = "Performance Rating is required.";
    // }
    if (!validateNumeric(formData.performanceRating)) {
      err.performanceRating = "Value must be between 1 to 100";
    }

    // if (formData.subContructorId === 0 || formData.subContructorId.toString().length === 0) {
    //   err.subContructorId = "Labour Supply is required.";
    // }

    if (formData.projectIdCommaSeperated === "") {
      err.projectIdCommaSeperated = "Project is required.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const validateNumeric = (entText: any) => {
    // Regular expression for numeric validation
    const numRegex = /^[0-9]*$/;
    return numRegex.test(entText);
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      // calling save api
      try {
        const responseData = await postData(saveManpower, formData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    // setFormData({
    //   manpowerId: 0,
    //   companyId: companyData.companyId,
    //   manpowerCode: "",
    //   manpowerName: "",
    //   designationId: 0,
    //   engineerInchargeId: 0,
    //   formanInchargeId: 0,
    //   chargehandInchargeId: 0,
    //   gangLeaderInchargeId: 0,
    //   skillUnskill: "",
    //   subContructorId: 0,
    //   performanceRating: 0,
    //   projectIdCommaSeperated: "",
    //   userId: userData.userId,
    // });

    // setFormError({
    //   manpowerCode: "",
    //   manpowerName: "",
    //   designationId: "",
    //   engineerInchargeId: "",
    //   formanInchargeId: "",
    //   chargehandInchargeId: "",
    //   gangLeaderInchargeId: "",
    //   skillUnskill: "",
    //   performanceRating: "",
    //   subContructorId: "",
    //   projectIdCommaSeperated: ""
    // });

    // setProjectDefaultData([]);
    reRoute("/manageManPower");
  };

  const options = manPowerTradeDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));
  const options1 = engineerInchargeDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));
  const options2 = foremanInchargeDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));
  const options3 = chargehandInchargeDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));
  const options4 = gangLeaderInchargeDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));
  const options5 = labourSupplyDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("designationId", selectedOption.value);
      setFormData({
        ...formData,
        designationId: selectedOption.value,
      });
    } else {
      validatefields("designationId", ""); // or any other default value
      setFormData({
        ...formData,
        designationId: 0, // Set to an empty string or any other default value
      });
    }
  };
  const onChangeHandler2 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("engineerInchargeId", selectedOption.value);
      setFormData({
        ...formData,
        engineerInchargeId: selectedOption.value,
      });
    } else {
      validatefields("engineerInchargeId", ""); // or any other default value
      setFormData({
        ...formData,
        engineerInchargeId: 0, // Set to an empty string or any other default value
      });
    }
  };
  const onChangeHandler3 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("formanInchargeId", selectedOption.value);
      setFormData({
        ...formData,
        formanInchargeId: selectedOption.value,
      });
    } else {
      validatefields("formanInchargeId", ""); // or any other default value
      setFormData({
        ...formData,
        formanInchargeId: 0, // Set to an empty string or any other default value
      });
    }
  };
  const onChangeHandler4 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("chargehandInchargeId", selectedOption.value);
      setFormData({
        ...formData,
        chargehandInchargeId: selectedOption.value,
      });
    } else {
      validatefields("chargehandInchargeId", ""); // or any other default value
      setFormData({
        ...formData,
        chargehandInchargeId: 0, // Set to an empty string or any other default value
      });
    }
  };
  const onChangeHandler5 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("gangLeaderInchargeId", selectedOption.value);
      setFormData({
        ...formData,
        gangLeaderInchargeId: selectedOption.value,
      });
    } else {
      validatefields("gangLeaderInchargeId", ""); // or any other default value
      setFormData({
        ...formData,
        gangLeaderInchargeId: 0, // Set to an empty string or any other default value
      });
    }
  };
  const onChangeHandler6 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("subContructorId", selectedOption.value);
      setFormData({
        ...formData,
        subContructorId: selectedOption.value,
      });
    } else {
      validatefields("subContructorId", ""); // or any other default value
      setFormData({
        ...formData,
        subContructorId: 0, // Set to an empty string or any other default value
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageManPower"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Edit Manpower
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageManPower">
                      <FontAwesomeIcon icon={faPersonDigging} />
                      <span>Manage Manpower</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Manpower
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Manpower Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="manpowerCode"
                  name="manpowerCode"
                  value={formData.manpowerCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.manpowerCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Manpower Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="manpowerName"
                  name="manpowerName"
                  value={formData.manpowerName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.manpowerName}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Manpower Trade / Designation{" "}
                  <span className="text-danger">*</span>
                </label>
                {/* <input type="text" className="form-control" id="manpowerTrade" name="manpowerTrade" value={formData.manpowerTrade} onChange={onChangeHandler} /> */}
                {/* <select name="designationId" className="form-select" value={formData.designationId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {manPowerTradeDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="manpowerTrade"
                  value={options.find(
                    (option) => option.value === formData.designationId
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select Manpower Trade"
                />
                <span className="text-danger">{formError.designationId}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Engineer Incharge <span className="text-danger">*</span>
                </label>
                {/* <select name="engineerInchargeId" id="engineerInchargeId" className="form-select" value={formData.engineerInchargeId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {engineerInchargeDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="engineerInchargeId"
                  value={options1.find(
                    (option) => option.value === formData.engineerInchargeId
                  )}
                  onChange={onChangeHandler2}
                  options={options1}
                  placeholder="Select Engineer Incharge"
                />
                <span className="text-danger">
                  {formError.engineerInchargeId}
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Foreman Incharge <span className="text-danger">*</span>
                </label>
                {/* <select name="formanInchargeId" id="formanInchargeId" className="form-select" value={formData.formanInchargeId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {foremanInchargeDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="formanInchargeId"
                  value={options2.find(
                    (option) => option.value === formData.formanInchargeId
                  )}
                  onChange={onChangeHandler3}
                  options={options2}
                  placeholder="Select Forman Incharge"
                />
                <span className="text-danger">
                  {formError.formanInchargeId}
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Chargehand Incharge</label>
                {/* <select name="chargehandInchargeId" id="chargehandInchargeId" className="form-select" value={formData.chargehandInchargeId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {chargehandInchargeDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="chargehandInchargeId"
                  value={options3.find(
                    (option) => option.value === formData.chargehandInchargeId
                  )}
                  onChange={onChangeHandler4}
                  options={options3}
                  placeholder="Select Chargehand Incharge"
                />
                <span className="text-danger">
                  {formError.chargehandInchargeId}
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">Gangleader Incharge</label>
                {/* <select name="gangLeaderInchargeId" id="gangLeaderInchargeId" className="form-select" value={formData.gangLeaderInchargeId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {gangLeaderInchargeDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="gangLeaderInchargeId"
                  value={options4.find(
                    (option) => option.value === formData.gangLeaderInchargeId
                  )}
                  onChange={onChangeHandler5}
                  options={options4}
                  placeholder="Select GangLeader Incharge"
                />
                <span className="text-danger">
                  {formError.gangLeaderInchargeId}
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Skill/unskill</label>
                <input
                  type="text"
                  className="form-control"
                  id="skillUnskill"
                  name="skillUnskill"
                  value={formData.skillUnskill}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.skillUnskill}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Performance Rating</label>
                <input
                  type="number"
                  className="form-control"
                  id="performanceRating"
                  min={0}
                  name="performanceRating"
                  value={formData.performanceRating}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">
                  {formError.performanceRating}
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Labour Supply/Sub Contractor
                </label>
                {/* <select name="subContructorId" id="subContructorId" className="form-select" value={formData.subContructorId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {labourSupplyDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="subContructorId"
                  value={options5.find(
                    (option) => option.value === formData.subContructorId
                  )}
                  onChange={onChangeHandler6}
                  options={options5}
                  placeholder="Select Sub Contractor"
                />
                <span className="text-danger">{formError.subContructorId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Projects <span className="text-danger">*</span>
                </label>
                {/* <Select name="projectIdCommaSeperated" id="projectIdCommaSeperated" isMulti={true} options={projectDrpDwnData} defaultValue={projectDefaultData} isSearchable={true} onChange={(item: any) => handleProjectSelect(item)} /> */}
                <Multiselect
                  displayValue="label"
                  options={projectDrpDwnData}
                  selectedValues={projectDefaultData}
                  onSelect={(item: any) => handleProjectSelect(item)}
                  onRemove={(item: any) => handleProjectSelect(item)}
                  showCheckbox
                />
                <span className="text-danger">
                  {formError.projectIdCommaSeperated}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
