import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSave,
  faXmark,
  faTrowelBricks,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { getAllUomDrpDwn, saveMaterial } from "../../../services/Endpoints";
import { getData, postData } from "../../../services/API";
import { Link, useNavigate } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
import Select from "react-select";

export interface IAddMaterialProps {}

interface IMaterialData {
  materialId: number;
  companyId: number;
  uomId: number;
  materialCode: string;
  materialName: string;
  erpCode: string;
  materialQty: number;
  userId: number;
}

interface IFormErrorData {
  materialCode: string;
  materialName: string;
  materialQty: string;
  erpCode: string;
  uomId: string;
}

export function AddMaterial() {
  // Declarations //
  const reRoute = useNavigate();
  const { userData, companyData } = useAuth();

  const [uomDrpDwnData, setUomDrpDwnData] = useState<any[]>([]);

  const [formData, setFormData] = useState<IMaterialData>({
    materialId: 0,
    companyId: companyData.companyId,
    uomId: 0,
    materialCode: "",
    materialName: "",
    erpCode: "",
    materialQty: 0,
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    materialCode: "",
    materialName: "",
    materialQty: "",
    erpCode: "",
    uomId: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchAllUOMDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getAllUomDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setUomDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllUOMDrpDwn();
  }, [companyData.companyId]);

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageMaterial");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const spaceRegex = /^\s|\s$/;
    const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
    if (fieldName.includes("materialCode")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("erpCode")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 1 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("Name")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 50 characters of alphabets only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 50 characters of alphabets only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("materialQty")) {
      if (fieldValue === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Material Qty is required.",
        });
        return false;
      } else if (fieldValue > 0) {
        if (fieldValue < 0) {
          setFormError({
            ...formError,
            [fieldName]: "Value must be greater than 0.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("uomId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "UOM is required." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.materialCode.trim() === "") {
      err.materialCode = "Material Code is required.";
    } else {
      if (validatefields("materialCode", formData.materialCode)) {
        err.materialCode =
          "MMust be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (formData.materialName.trim() === "") {
      err.materialName = "Material Name is required.";
    } else {
      if (validatefields("materialName", formData.materialName)) {
        err.materialName =
          "Must be between 2 and 50 characters of alphabets only, without empty spaces at the beginning.";
      }
    }

    if (formData.materialQty === 0) {
      err.materialQty = "Material Qty is required.";
    } else {
      if (validatefields("materialQty", formData.materialQty)) {
        err.materialQty = "Value must be grater than 0.";
      }
    }

    if (validatefields("erpCode", formData.erpCode)) {
      err.erpCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (formData.uomId === 0 || formData.uomId.toString().length === 0) {
      err.uomId = "UOM is required.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      // calling save api
      try {
        const responseData = await postData(saveMaterial, formData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    setFormData({
      materialId: 0,
      companyId: companyData.companyId,
      uomId: 0,
      materialCode: "",
      materialName: "",
      erpCode: "",
      materialQty: 0,
      userId: userData.userId,
    });

    setFormError({
      materialCode: "",
      materialName: "",
      materialQty: "",
      erpCode: "",
      uomId: "",
    });
  };

  const options = uomDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("uomId", selectedOption.value);
      setFormData({
        ...formData,
        uomId: selectedOption.value,
      });
    } else {
      validatefields("uomId", ""); // or any other default value
      setFormData({
        ...formData,
        uomId: 0, // Set to an empty string or any other default value
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageMaterial"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Add Material
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageMaterial">
                      <FontAwesomeIcon icon={faTrowelBricks} />
                      <span>Manage Material</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Material
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Material Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="materialCode"
                  value={formData.materialCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.materialCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Material Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="materialName"
                  value={formData.materialName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.materialName}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Material Qty. <span className="text-danger">*</span>
                </label>
                {/* <select name="materialQty" className="form-select" value={formData.materialQty} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select> */}
                <input
                  type="number"
                  className="form-control"
                  name="materialQty"
                  min={0}
                  value={formData.materialQty === 0 ? "" : formData.materialQty}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.materialQty}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">ERP Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="erpCode"
                  value={formData.erpCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.erpCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  UOM <span className="text-danger">*</span>
                </label>
                {/* <select name="uomId" className="form-select" value={formData.uomId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {uomDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="uomId"
                  value={options.find(
                    (option) => option.value === formData.uomId
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select UOM"
                />
                <span className="text-danger">{formError.uomId}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Clear</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
