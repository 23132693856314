import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
  faLessThan,
  faGreaterThan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  useReactTable,
  flexRender,
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getCoreRowModel,
} from "@tanstack/react-table";
import { CustomTooltip } from "./Tooltip";
import "../../styles/DataTable.css";

interface DataTableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  totalRows: number;
  fetchData: (
    page: number,
    pageSize: number,
    sorting: SortingState,
    filters: Record<string, string>
  ) => void;
  isLoading: boolean;
}

function DataTable<T>({
  data,
  columns,
  totalRows,
  fetchData,
  isLoading,
}: DataTableProps<T>) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState<Record<string, string>>(
    {}
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchInputs, setSearchInputs] = useState<Record<string, string>>({});
  const stickyCols = [/*"Sl.No", */ "Actions"];

  const getCellContent = (value: any): string => {
    if (value === null || value === undefined) return "";
    if (typeof value === "object") return JSON.stringify(value);
    return String(value);
  };

  const renderCell = (cell: any) => {
    const content = flexRender(cell.column.columnDef.cell, cell.getContext());
    const tooltipContent = getCellContent(cell.getValue());
    const isSticky = stickyCols.includes(
      cell.column.columnDef.header as string
    );

    return (
      <CustomTooltip content={tooltipContent}>
        <div
          className={`cell-content ${isSticky ? "sticky-cell" : ""}`}
          style={{
            position: isSticky ? "sticky" : "static",
            // left:
            //   isSticky && cell.column.columnDef.header === "Sl.No" ? 0 : "auto",
            right:
              isSticky && cell.column.columnDef.header === "Actions"
                ? 0
                : "auto",
            backgroundColor: isSticky ? "white" : "transparent",
            zIndex: isSticky ? 1 : 0,
          }}
        >
          {content}
        </div>
      </CustomTooltip>
    );
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      sorting,
    },
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({ pageIndex, pageSize });
        setPageIndex(newState.pageIndex);
        setPageSize(newState.pageSize);
        fetchData(
          newState.pageIndex + 1,
          newState.pageSize,
          sorting,
          columnFilters
        );
      }
    },
    onSortingChange: (updater) => {
      const newSorting =
        updater instanceof Function ? updater(sorting) : updater;
      setSorting(newSorting);
      fetchData(pageIndex + 1, pageSize, newSorting, columnFilters);
    },
    pageCount: Math.ceil(totalRows / pageSize),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
  });

  const handleSearch = (columnId: string) => {
    const newFilters = {
      ...columnFilters,
      [columnId]: searchInputs[columnId] || "",
    };
    setColumnFilters(newFilters);
    fetchData(1, pageSize, sorting, newFilters);
  };

  const handleClearSearch = (columnId: string) => {
    const newSearchInputs = { ...searchInputs };
    delete newSearchInputs[columnId];
    setSearchInputs(newSearchInputs);

    const newFilters = { ...columnFilters };
    delete newFilters[columnId];
    setColumnFilters(newFilters);

    fetchData(1, pageSize, sorting, newFilters);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    columnId: string
  ) => {
    if (event.key === "Enter") {
      handleSearch(columnId);
    }
  };

  useEffect(() => {
    Object.entries(searchInputs).forEach(([columnId, value]) => {
      if (value === "") {
        handleClearSearch(columnId);
      }
    });
  }, [searchInputs]);

  return (
    <div className="table-container">
      <div className="table-scroll-container">
        {isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div
            className={`table-scroll-container ${
              pageSize > 5 ? "scrollable" : ""
            }`}
          >
            <table className="sticky-table">
              <colgroup>
                {table.getAllColumns().map((column) => (
                  <col
                    key={column.id}
                    style={{ width: `${column.getSize()}px` }}
                  />
                ))}
              </colgroup>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const isSticky = stickyCols.includes(
                        header.column.columnDef.header as string
                      );
                      return (
                        <th
                          key={header.id}
                          style={{
                            position: isSticky ? "sticky" : "static",
                            // left:
                            //   isSticky &&
                            //   header.column.columnDef.header === "Sl.No"
                            //     ? 0
                            //     : "auto",
                            right:
                              isSticky &&
                              header.column.columnDef.header === "Actions"
                                ? 0
                                : "auto",
                            backgroundColor: isSticky ? "white" : "transparent",
                            zIndex: isSticky ? 2 : 0,
                          }}
                        >
                          <div className="header-content">
                            <div className="header-text">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {header.column.getCanSort() && (
                                <button
                                  onClick={header.column.getToggleSortingHandler()}
                                  className="ml-2"
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      header.column.getIsSorted() === "asc"
                                        ? faSortUp
                                        : header.column.getIsSorted() === "desc"
                                        ? faSortDown
                                        : faSort
                                    }
                                  />
                                </button>
                              )}
                            </div>
                            {header.column.getCanFilter() && (
                              <div className="header-search relative">
                                <input
                                  type="text"
                                  value={searchInputs[header.column.id] || ""}
                                  onChange={(e) =>
                                    setSearchInputs((prev) => ({
                                      ...prev,
                                      [header.column.id]: e.target.value,
                                    }))
                                  }
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, header.column.id)
                                  }
                                  placeholder={`${
                                    header.column.columnDef.header as string
                                  }`}
                                  className="w-full border shadow rounded pl-8 pr-2 py-1 text-sm"
                                />
                                <button
                                  onClick={() => handleSearch(header.column.id)}
                                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                </button>
                                {searchInputs[header.column.id] && (
                                  <button
                                    onClick={() =>
                                      handleClearSearch(header.column.id)
                                    }
                                    className="absolute right-8 top-1/2 transform -translate-y-1/2 text-gray-400"
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          position: stickyCols.includes(
                            cell.column.columnDef.header as string
                          )
                            ? "sticky"
                            : "static",
                          // left:
                          //   cell.column.columnDef.header === "Sl.No"
                          //     ? 0
                          //     : "auto",
                          right:
                            cell.column.columnDef.header === "Actions"
                              ? 0
                              : "auto",
                          backgroundColor: stickyCols.includes(
                            cell.column.columnDef.header as string
                          )
                            ? "white"
                            : "transparent",
                          zIndex: stickyCols.includes(
                            cell.column.columnDef.header as string
                          )
                            ? 1
                            : 0,
                        }}
                      >
                        {renderCell(cell)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="pagination">
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
            setPageSize(Number(e.target.value));
            fetchData(
              pageIndex + 1,
              Number(e.target.value),
              sorting,
              columnFilters
            );
          }}
          className="bg-transparent"
        >
          {[5, 10, 15, 20, 25].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="border-0 bg-transparent"
        >
          <FontAwesomeIcon icon={faLessThan} />
        </button>
        <span>
          Page {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="border-0 bg-transparent"
        >
          <FontAwesomeIcon icon={faGreaterThan} />
        </button>
      </div>
    </div>
  );
}

export default DataTable;
