import React, { useEffect } from "react";
import { useState } from "react";

// Custom Tooltip Component
export const CustomTooltip: React.FC<{
  content: React.ReactNode;
  children: React.ReactNode;
}> = ({ content, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    setPosition({
      top: rect.bottom + scrollTop,
      left: rect.left + scrollLeft,
    });
    setShowTooltip(true);
  };

  useEffect(() => {
    if (showTooltip && tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      // Adjust position if tooltip would overflow right edge
      if (position.left + tooltipRect.width > viewportWidth) {
        setPosition((prev) => ({
          ...prev,
          left: viewportWidth - tooltipRect.width - 10, // 10px margin
        }));
      }
    }
  }, [showTooltip, position.left]);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          ref={tooltipRef}
          className="tooltip-content"
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};
