import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faDownload,
  faFileExcel,
  faGears,
  faHouse,
  faMagnifyingGlass,
  faSave,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect } from "react";
import { taskBulkUpload } from "../../../services/Endpoints";
import { postData } from "../../../services/API";
import { useAuth } from "../../../providers/AuthContext";
import { bulkUploadStatusBtn } from "../../../components/common/bulkUploadStatusBtn";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { ExportToExcel } from "../../../services/ExportToFile";

export interface ITaskBulkUploadProps {}

interface IBulkUploadData {
  file: File | null;
  // companyId: number,
  UserId: number;
  ProjectId: number;
}

interface IFormErrorData {
  file: string;
}

interface ITaskRowData {
  activityName: string;
  taskCode: string;
  taskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: string;
  uom: string;
  costCode: any;
  p6code: any;
  taktCode: any;
  addProjectNames: any;
  deleteProjectNames: any;
  success: string;
  message: string;
}

export function TaskBulkUpload() {
  // Declarations //
  // const reRoute = useNavigate();
  const { userData, companyData, projectData } = useAuth();

  const [taskRowData, setTaskRowData] = useState<ITaskRowData[]>();

  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileName, setSelectedFileName] = useState("");

  const [formData, setFormData] = useState<IBulkUploadData>({
    file: null,
    // companyId: companyData.companyId,
    UserId: userData.userId,
    ProjectId: projectData.projectId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    file: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Datagrid table data
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { headerName: "Sl.No", valueGetter: "node.rowIndex + 1", width: 100 },
    {
      headerName: "Task Code",
      field: "taskCode",
      filter: true,
      tooltipField: "taskCode",
      headerTooltip: "Task Code",
    },
    {
      headerName: "Task Name",
      field: "taskName",
      filter: true,
      tooltipField: "taskName",
      headerTooltip: "Task Name",
    },
    {
      headerName: "Planed Start Date",
      field: "planedStartDate",
      filter: true,
      tooltipField: "planedStartDate",
      headerTooltip: "Planed Start Date",
    },
    {
      headerName: "Planed Finish Date ",
      field: "planedFinishDate",
      filter: true,
      tooltipField: "planedFinishDate",
      headerTooltip: "Planed Finish Date ",
    },
    {
      headerName: "Budget Qty ",
      field: "budgetQty",
      filter: true,
      tooltipField: "budgetQty",
      headerTooltip: "Budget Qty ",
    },
    {
      headerName: "UOM ",
      field: "uom",
      filter: true,
      tooltipField: "uom",
      headerTooltip: "UOM ",
    },
    {
      headerName: "Cost Code",
      field: "costCode",
      filter: true,
      width: 200,
      tooltipField: "costCode",
      headerTooltip: "Cost Code",
    },
    {
      headerName: "P6 Code",
      field: "p6code",
      filter: true,
      width: 200,
      tooltipField: "p6code",
      headerTooltip: "P6 Code",
    },
    {
      headerName: "TAKT Code",
      field: "taktCode",
      filter: true,
      width: 200,
      tooltipField: "taktCode",
      headerTooltip: "TAKT Code",
    },
    {
      headerName: "Add Project Names",
      field: "addProjectNames",
      filter: true,
      width: 200,
      tooltipField: "addProjectNames",
      headerTooltip: "Add Project Names",
    },
    {
      headerName: "Delete Project Names",
      field: "deleteProjectNames",
      filter: true,
      width: 200,
      tooltipField: "deleteProjectNames",
      headerTooltip: "Delete Project Names",
    },
    {
      headerName: "Upload Status",
      field: "success",
      cellRenderer: bulkUploadStatusBtn,
      width: 150,
    },
    {
      headerName: "Remarks",
      field: "message",
      width: 250,
      tooltipField: "message",
      headerTooltip: "Remarks",
    },
  ]);

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const handleFileButtonClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx";
    fileInput.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prevState) => ({
            ...prevState,
            file: file,
          }));
          setSelectedFile(file);
          setSelectedFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.file === null) {
      err.file = "Please select a file.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm && selectedFile) {
      //setting loader state
      setIsSubmitting(true);

      const fmData = new FormData();
      fmData.append("file", selectedFile);
      fmData.append("companyId", companyData.companyId);
      fmData.append("userId", userData.userId);

      // calling save api
      try {
        const responseData = await postData(taskBulkUpload, fmData);
        if (responseData.success) {
          if (responseData.data != null && responseData.data.length > 0) {
            setTaskRowData([]);
            setTaskRowData(responseData.data);
          }
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          if (responseData.data != null && responseData.data.length > 0) {
            setTaskRowData([]);
            setTaskRowData(responseData.data);
          }
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    setFormData({
      file: null,
      // companyId: companyData.companyId,
      UserId: userData.userId,
      ProjectId: projectData.projectId,
    });

    setFormError({
      file: "",
    });

    setSelectedFileName("");
  };

  const onDownloadHandler = () => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href =
      "https://youngconstrunction.blob.core.windows.net/civilproject/sample_task_details.xlsx";
    link.download = "sample_task_details.xlsx"; // Set the file name

    // Programmatically click the anchor element to trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the anchor element
  };

  const onExportToExcel = () => {
    const columns = JSON.stringify(
      columnDefs
        .filter((x) => x.headerName !== "Actions" && x.headerName !== "Status")
        .map((column) => {
          if (column.headerName === "Sl.No") {
            return {
              label: column.headerName,
              value: "slNo",
            };
          } else {
            return {
              label: column.headerName,
              value: column.field,
            };
          }
        })
    );

    // Filter staffRowData based on selectedIdsArray if it has values
    var filteredStaffData = taskRowData?.filter(
      (row) => row.success === "Failed"
    );

    let content = "{}";
    if (filteredStaffData !== undefined) {
      let data = JSON.stringify(
        filteredStaffData.map((row, index) => {
          return {
            slNo: index + 1,
            taskCode: row.taskCode,
            taskName: row.taskName,
            planedStartDate: row.planedStartDate,
            planedFinishDate: row.planedFinishDate,
            budgetQty: row.budgetQty,
            uom: row.uom,
            costCode: row.costCode,
            p6code: row.p6code,
            taktCode: row.taktCode,
            addProjectNames: row.addProjectNames,
            deleteProjectNames: row.deleteProjectNames,
            message: row.message,
          };
        })
      );

      content = data;
    }

    ExportToExcel("Failed Task List", columns, content);
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageTask"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Task Bulk Upload
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageTask">
                      <FontAwesomeIcon icon={faGears} />
                      <span>Manage Task</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Task Bulk Upload
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Demo Excel/Excel File</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={onDownloadHandler}
                  disabled={isSubmitting}
                  style={{ marginRight: 10 }}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    style={{ marginRight: 5 }}
                  />
                  <span>Download</span>
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Choose File <span className="text-danger">*</span>
                </label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="upload-area">
                  <FontAwesomeIcon icon={faUpload} size="2xl" />
                  {/* <span className="upload-text mt-2">Click to upload or drag and drop</span> */}
                  {/* <span className="upload-size-text mt-2">Max. File Size: 30MB</span> */}
                  <button
                    type="button"
                    className="btn custom-btn mt-2"
                    onClick={handleFileButtonClick}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ marginRight: 5 }}
                    />
                    Browse File
                  </button>
                </div>
                <span className="text-danger">{formError.file}</span>
              </div>
              <div className="col-md-6">
                {selectedFileName && (
                  <label className="form-label">
                    Selected File : {selectedFileName}
                  </label>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Clear</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      {taskRowData && (
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h6>Sub Task List</h6>
              </div>
              <button
                type="button"
                className="btn custom-btn"
                onClick={onExportToExcel}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ marginRight: 5 }}
                />
                <span>Export Excel</span>
              </button>
            </div>
            <div className="mt-3">
              <div className="ag-theme-quartz" style={{ height: 380 }}>
                <AgGridReact
                  rowData={taskRowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // autoSizeStrategy={{ type: 'fitCellContents' }}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={5}
                  paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                  tooltipShowDelay={500}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
