import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { acivateDeactivate, deleteData, getData } from "../../../services/API";
import {
  actDeactManpower,
  deleteManpowerData,
  getAllManpower,
} from "../../../services/Endpoints";
import { useAuth } from "../../../providers/AuthContext";
import { StatusBtn } from "../../../components/common/StatusBtns";
import { DataGridActionBtns } from "../../../components/common/DataGridActionBtns";
import activateImg from "../../../assets/activate.gif";
import deleteImg from "../../../assets/Delete.gif";
import { ExportToExcel } from "../../../services/ExportToFile";
import ConfirmationModal from "../../../components/common/ConfirmationModel";
import { PageLayout } from "../../../components/common/PageLayout";
import { DataTableWrapper } from "../../../components/common/DataTableWrapper";

interface IManPowerTabData {
  manpowerId: number;
  companyId: number;
  manpowerCode: string;
  manpowerName: string;
  manpowerTrade: string;
  designationId: number;
  engineerInchargeId: number;
  formanInchargeId: number;
  chargehandInchargeId: number;
  gangLeaderInchargeId: number;
  skillUnskill: string;
  subContructorId: number;
  performanceRating: number;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: string;
  designationName: string;
  engineerInchargeName: string;
  formanInchargeName: string;
  chargehandInchargeName: string;
  gangLeaderInchargeName: string;
  subContractorName: string;
  manpowerProjectMapping: string;
}

const mappings: { key: string; value: string; searchColumnName: string }[] = [
  {
    key: "manpowerCode",
    value: "manpower_code",
    searchColumnName: "manpower_code",
  },
  {
    key: "manpowerName",
    value: "manpower_name",
    searchColumnName: "manpower_name",
  },
  {
    key: "designationName",
    value: "designation_name",
    searchColumnName: "designation_name",
  },
  {
    key: "engineerInchargeName",
    value: "EngineerInchargeName",
    searchColumnName: "engineer_incharge",
  },
  {
    key: "formanInchargeName",
    value: "FormanInchargeName",
    searchColumnName: "forman_incharge",
  },
  { key: "activeFlag", value: "active_flag", searchColumnName: "active_flag" },
];

const getMappedValue = (
  sortColumn?: string,
  searchColumn?: string
): string | undefined => {
  const mapping = mappings.find(
    (m) => m.key === (sortColumn !== "" ? sortColumn : searchColumn)
  );
  return mapping
    ? sortColumn !== ""
      ? mapping.value
      : mapping.searchColumnName
    : "";
};

export function ManageManPower() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, companyData } = useAuth();

  const [manPowerData, setManPowerData] = useState<IManPowerTabData[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  const [selectedData, setSelectedData] = useState<any>({});
  const [showToggleConfirm, setShowToggleConfirm] = useState(false);
  const [showToggleAlert, setShowToggleAlert] = useState(false);
  const [toggleMessage, setToggleMessage] = useState("");

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const columnsData: ColumnDef<IManPowerTabData>[] = [
    {
      header: "Sl.No",
      cell: ({ row }) => {
        return pageIndex * pageSize + row.index + 1;
      },
      size: 70,
    },
    {
      header: "Manpower Code",
      accessorKey: "manpowerCode",
      size: 150,
      enableSorting: true,
      enableColumnFilter: true,
      id: "manpowerCode",
    },
    {
      header: "Manpower Name",
      accessorKey: "manpowerName",
      size: 200,
      enableSorting: true,
      enableColumnFilter: true,
      id: "manpowerName",
    },
    {
      header: "Manpower Trade",
      accessorKey: "designationName",
      size: 170,
      enableSorting: true,
      enableColumnFilter: true,
      id: "designationName",
    },
    {
      header: "Engineer Incharge",
      accessorKey: "engineerInchargeName",
      size: 170,
      enableSorting: true,
      enableColumnFilter: true,
      id: "engineerInchargeName",
    },
    {
      header: "Foreman Incharge",
      accessorKey: "formanInchargeName",
      size: 170,
      enableSorting: true,
      enableColumnFilter: true,
      id: "formanInchargeName",
    },
    {
      header: "Chargehand Incharge",
      accessorKey: "chargehandInchargeName",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "chargehandInchargeName",
    },
    {
      header: "Gangleader Incharge",
      accessorKey: "gangLeaderInchargeName",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "gangLeaderInchargeName",
    },
    {
      header: "Skill/Unskill",
      accessorKey: "skillUnskill",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "skillUnskill",
    },
    {
      header: "Performance Rating",
      accessorKey: "performanceRating",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "performanceRating",
    },
    {
      header: "Labour Supply",
      accessorKey: "subContractorName",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "subContractorName",
    },
    {
      header: "Projects Names",
      accessorKey: "manpowerProjectMapping",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "manpowerProjectMapping",
    },
    {
      header: "Created On",
      accessorKey: "createdTime",
      size: 170,
      enableSorting: false,
      enableColumnFilter: false,
      id: "createdTime",
    },
    {
      header: "Status",
      cell: ({ row }) => (
        <StatusBtn data={row.original} colDef={{ headerName: "" }} />
      ),
      accessorKey: "activeFlag",
      size: 120,
      enableSorting: true,
      enableColumnFilter: false,
      id: "activeFlag",
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <DataGridActionBtns
          data={row.original}
          onViewClick={onEditClickHandler}
          onDeleteClick={onDeleteClickHandler}
          onSwitchClick={onSwitchClickHandler}
        />
      ),
      size: 150,
    },
  ];

  const fetchData = async (
    page: number,
    pageSize: number,
    sorting: SortingState,
    filters: Record<string, string>
  ) => {
    setIsLoading(true);
    const filterData =
      location.search.split("=")[1] === "Active"
        ? "&IsActive=1"
        : location.search.split("=")[1] === "Inactive"
        ? "&IsActive=0"
        : "";

    var searchParams = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        const mappedValue = getMappedValue("", key);
        searchParams = `&SearchColumn=${mappedValue}&SearchQuery=${value}`;
      }
    });

    // Modify this part to handle sorting correctly and safely
    let sortParams = "";
    if (sorting && sorting.length > 0 && sorting[0]) {
      const { id, desc } = sorting[0];
      if (id) {
        const mappedValue = getMappedValue(id);
        sortParams = `&SortColumn=${mappedValue}&SortDirection=${
          desc ? "desc" : "asc"
        }`;
      }
    }

    try {
      const responseData = await getData(
        `${getAllManpower}CompanyId=${companyData.companyId}&UserId=${userData.userId}&PageNumber=${page}&PageSize=${pageSize}${filterData}${searchParams}${sortParams}`
      );

      if (responseData.success) {
        setManPowerData(responseData.data);
        setTotalRows(responseData.totalCount);
      } else {
        setManPowerData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllManpowerData(1, 5);
  }, []);

  const fetchAllManpowerData = async (page: number, size: number) => {
    fetchData(page, size, [], {});
  };

  const onEditClickHandler = (row: any) => {
    var id = row.manpowerId;
    navigate("/editManpower/" + id);
  };

  const onDeleteClickHandler = (row: any) => {
    setDeleteMessage("Are you sure to Delete this record?");
    setshowDeleteConfirm(true);
    setSelectedData({
      manpowerId: row.manpowerId,
      userId: userData.userId,
    });
  };
  const handleDeleteConfirm = () => {
    deleteManpower(selectedData.manpowerId, selectedData.userId);
    closeDeleteConfirm();
  };

  const handleDeleteAlert = () => {
    fetchAllManpowerData(pageIndex + 1, pageSize);
    closeDeleteAlert();
  };

  const closeDeleteConfirm = () => {
    setshowDeleteConfirm(false);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteCancel = () => {
    closeDeleteConfirm();
  };

  const deleteManpower = async (manpowerId: any, UserId: any) => {
    // Call delete API
    try {
      const responseData = await deleteData(
        deleteManpowerData + `ManpowerId=${manpowerId}&UserId=${UserId}`
      );

      if (responseData.length > 0) {
        if (responseData[0].success) {
          setDeleteMessage(responseData[0].message);
          setShowDeleteAlert(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onSwitchClickHandler = (row: any) => {
    setToggleMessage(
      `Are you sure to ${
        row.activeFlag === 0 ? "Activate" : "Deactivate"
      } this Manpower?`
    );
    setShowToggleConfirm(true);
    setSelectedData({
      manpowerId: row.manpowerId,
      userId: userData.userId,
    });
  };

  const handleToggleConfirm = () => {
    activateDeactivateManpower(selectedData.manpowerId, selectedData.userId);
    closeToggleConfirm();
  };

  const handleToggleAlert = () => {
    fetchAllManpowerData(pageIndex + 1, pageSize);
    closeToggleAlert();
  };

  const closeToggleConfirm = () => {
    setShowToggleConfirm(false);
  };

  const closeToggleAlert = () => {
    setShowToggleAlert(false);
  };

  const handleToggleCancel = () => {
    closeToggleConfirm();
  };

  const activateDeactivateManpower = async (manpowerId: any, UserId: any) => {
    // Call getDataById API
    try {
      const responseData = await acivateDeactivate(
        actDeactManpower + `ManpowerId=${manpowerId}&UserId=${UserId}`
      );
      if (responseData.success) {
        setToggleMessage(responseData.message);
        setShowToggleAlert(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddBtn = () => {
    navigate("/addManPower");
  };

  const handleBulkUploadBtn = () => {
    navigate("/manpowerBulkUpload");
  };

  const onExportToExcel = async () => {
    try {
      setIsExporting(true); // Start loading

      const columns = JSON.stringify(
        columnsData
          .filter((x) => x.header !== "Actions")
          .map((column) => {
            if (column.header === "Sl.No") {
              return {
                label: column.header,
                value: "slNo",
              };
            } else {
              return {
                label: column.header,
                value: column.id,
              };
            }
          })
      );

      const responseData = await getData(
        `${getAllManpower}CompanyId=${companyData.companyId}&UserId=${
          userData.userId
        }&PageNumber=${1}&PageSize=${totalRows}`
      );

      let content = "{}";
      if (responseData !== undefined) {
        let data = JSON.stringify(
          responseData.data.map((row: any, index: number) => {
            return {
              slNo: index + 1,
              manpowerCode: row.manpowerCode,
              manpowerName: row.manpowerName,
              designationName: row.designationName,
              engineerInchargeName: row.engineerInchargeName,
              formanInchargeName: row.formanInchargeName,
              chargehandInchargeName:
                row.chargehandInchargeId === 0
                  ? ""
                  : row.chargehandInchargeName,
              gangLeaderInchargeName:
                row.gangLeaderInchargeId === 0
                  ? ""
                  : row.gangLeaderInchargeName,
              skillUnskill: row.skillUnskill,
              subContractorName:
                row.subContructorId === 0 ? "" : row.subContractorName,
              performanceRating: row.performanceRating,
              manpowerProjectMapping: row.manpowerProjectMapping,
              activeFlag: row.activeFlag === 0 ? "Inactive" : "Active",
              createdTime: row.createdTime.split(" ")[0],
            };
          })
        );

        content = data;
      }

      await ExportToExcel("Manpower List", columns, content);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setIsExporting(false); // End loading
    }
  };

  return (
    <PageLayout
      title="Manage Manpower"
      breadcrumbItems={["Manage Manpower"]}
      showBackButton={
        location.search.split("?")[1] !== undefined &&
        location.search.split("?")[1].includes("adminDashboard")
      }
    >
      <DataTableWrapper
        title="Manpower List"
        data={manPowerData}
        columns={columnsData}
        totalRows={totalRows}
        isLoading={isLoading}
        isExporting={isExporting}
        onAddClick={handleAddBtn}
        onBulkUploadClick={handleBulkUploadBtn}
        onExportClick={onExportToExcel}
        fetchData={fetchData}
      />
      <ConfirmationModal
        show={showToggleConfirm}
        message={toggleMessage}
        image={activateImg}
        onConfirm={handleToggleConfirm}
        onCancel={handleToggleCancel}
      />
      <ConfirmationModal
        show={showToggleAlert}
        message={toggleMessage}
        image={activateImg}
        onConfirm={handleToggleAlert}
        showCancelButton={false}
      />
      <ConfirmationModal
        show={showDeleteConfirm}
        message={deleteMessage}
        image={deleteImg}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
      <ConfirmationModal
        show={showDeleteAlert}
        message={deleteMessage}
        image={deleteImg}
        onConfirm={handleDeleteAlert}
        showCancelButton={false}
      />
      {isExporting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="text-center text-white">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="mt-2">Exporting Excel...</div>
          </div>
        </div>
      )}
    </PageLayout>
  );
}
