import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faXmark,
  faSave,
  faGears,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import {
  getActivityDrpDwn,
  getActivityTypeDrpDwn,
  getAllProjectsDrpDwn,
  getSubTaskUomDrpDwn,
  getTaskDrpDwnForSubTask,
  saveSubTask,
} from "../../../services/Endpoints";
import { getData, postData } from "../../../services/API";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthContext";
// import Select from 'react-select';
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

export interface IAddSubTaskProps {}

interface ISubTaskData {
  subTaskId: number;
  companyId: number;
  activityId: number | null;
  taskId: number | null;
  subTaskCode: string;
  subTaskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: number;
  uomId: number;
  costCode: string;
  taktCode: string;
  p6code: string;
  activityTypeId: number;
  userId: number;
  projectIds: string;
}

interface IFormErrorData {
  activityId: string;
  taskId: string;
  subTaskCode: string;
  subTaskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: string;
  uomId: string;
  costCode: string;
  taktCode: string;
  p6code: string;
  activityTypeId: string;
  projectIds: string;
}

export function AddSubTask() {
  // Declarations //
  const reRoute = useNavigate();
  const { userData, companyData, projectData } = useAuth();

  const selectTaskRef = useRef<any>(null);
  const selectActRef = useRef<any>(null);
  const selectUomRef = useRef<any>(null);
  const selectActTypeRef = useRef<any>(null);

  const [formData, setFormData] = useState<ISubTaskData>({
    subTaskId: 0,
    companyId: companyData.companyId,
    activityId: 0,
    taskId: 0,
    subTaskCode: "",
    subTaskName: "",
    planedStartDate: "",
    planedFinishDate: "",
    budgetQty: 0,
    uomId: 0,
    costCode: "",
    taktCode: "",
    p6code: "",
    activityTypeId: 0,
    userId: userData.userId,
    projectIds: "",
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    activityId: "",
    taskId: "",
    subTaskCode: "",
    subTaskName: "",
    planedStartDate: "",
    planedFinishDate: "",
    budgetQty: "",
    uomId: "",
    costCode: "",
    taktCode: "",
    p6code: "",
    activityTypeId: "",
    projectIds: "",
  });

  const [projectDrpDwnData, setProjectDrpDwnData] = useState<any[]>([]);
  const [activityDrpDwnData, setActivityDrpDwnData] = useState<any[]>([]);
  const [taskDrpDwnData, setTaskDrpDwnData] = useState<any[]>([]);
  const [uomDrpDwnData, setUomDrpDwnData] = useState<any[]>([]);
  const [activityTypeDrpDwnData, setActivityTypeDrpDwnData] = useState<any[]>(
    []
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [restrictDate, setRestrictDate] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  const clearSelection = (reff: any, filedName: any) => {
    setFormData({ ...formData, [filedName]: 0 });
    if (reff.current) {
      reff.current.clearValue();
    }
  };

  const fetchActivityDrpDwn = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getActivityDrpDwn + `CompanyId=${companyData.companyId}`
      );
      if (responseData.success) {
        setActivityDrpDwnData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchActivityDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getActivityDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setActivityDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchAllUOMDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getSubTaskUomDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setUomDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchActivityTypeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getActivityTypeDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setActivityTypeDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchActivityDrpDwn();
    fetchAllUOMDrpDwn();
    fetchActivityTypeDrpDwn();
  }, [companyData.companyId, projectData.projectId]);

  const fetchProjectDrpDwn = async (taskId: number) => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllProjectsDrpDwn +
          `CompanyId=${companyData.companyId}&TaskId=${taskId}`
      );
      if (responseData.success) {
        setProjectDrpDwnData([]);
        responseData.data.forEach((item: any) => {
          setProjectDrpDwnData((prevState) => [
            ...prevState,
            { value: item.value, label: item.text },
          ]);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTaskDrpDwn = async (activityID: any) => {
    // Call getData API
    try {
      if (taskDrpDwnData.length > 0) {
        clearSelection(selectTaskRef, "taskId");
      }
      setTaskDrpDwnData([]);
      const responseData = await getData(
        getTaskDrpDwnForSubTask + `ActivityId=${activityID}`
      );
      if (responseData.success) {
        setTaskDrpDwnData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onDateChange = (event: any, field: any) => {
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));

    if (
      (field === "planedStartDate" || field === "planedFinishDate") &&
      event.target.value !== ""
    ) {
      const date = new Date(event.target.value);
      if (field === "planedStartDate") {
        setStartDate(date.toISOString());
        setRestrictDate(event.target.value);
      } else if (field === "planedFinishDate") {
        setEndDate(date.toISOString());
      }
    }
  };

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageSubTask");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
    const spaceRegex = /^\s|\s$/;
    if (fieldName.includes("subTaskCode")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 1 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("subTaskName")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 200 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 200) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 200 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("uomId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "UOM is required." });
        return false;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("Qty")) {
      if (fieldValue === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue > 0) {
        if (fieldValue < 1) {
          setFormError({
            ...formError,
            [fieldName]: "Value must be greater than 1.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("activityId")) {
      if (fieldValue.length === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Activity name is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("taskId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "Task is required." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("costCode")) {
      if (fieldValue !== null) {
        if (fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("taktCode")) {
      if (fieldValue !== null) {
        if (fieldValue !== null && fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("p6code")) {
      if (fieldValue !== null) {
        if (fieldValue !== null && fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("activityTypeId")) {
      if (fieldValue.length === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Activity Type Name is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (
      formData.activityId === 0 ||
      formData.activityId?.toString().length === 0
    ) {
      err.activityId = "Activity name is required.";
    }

    if (formData.taskId === 0 || formData.taskId?.toString().length === 0) {
      err.taskId = "Task is required.";
    }

    if (formData.subTaskName.trim() === "") {
      err.subTaskName = "Sub Task Name is required.";
    } else {
      if (validatefields("subTaskName", formData.subTaskName)) {
        err.subTaskName =
          "Must be between 2 to 50 characters, without empty spaces at the beginning.";
      }
    }

    if (formData.subTaskCode.trim() === "") {
      err.subTaskCode = "Sub Task Code is required.";
    } else {
      if (validatefields("subTaskCode", formData.subTaskCode)) {
        err.subTaskCode =
          "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (validatefields("budgetQty", formData.budgetQty)) {
      err.budgetQty = "Value must be greater than 1.";
    }

    if (formData.uomId === 0 || formData.uomId.toString().length === 0) {
      err.uomId = "UOM is required.";
    }

    if (validatefields("budgetQty", formData.budgetQty)) {
      err.budgetQty = "Value Must be greater than 1.";
    }

    if (validatefields("costCode", formData.costCode)) {
      err.costCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (validatefields("taktCode", formData.taktCode)) {
      err.taktCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (validatefields("p6code", formData.p6code)) {
      err.p6code =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (
      formData.activityTypeId === 0 ||
      formData.activityTypeId.toString().length === 0
    ) {
      err.activityTypeId = "Activity Type Name is required.";
    }

    if (formData.projectIds === "") {
      err.projectIds = "Project is required.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);
      const payloadData = {
        subTaskId: 0,
        companyId: companyData.companyId,
        activityId: formData.activityId,
        taskId: formData.taskId,
        subTaskCode: formData.subTaskCode,
        subTaskName: formData.subTaskName,
        planedStartDate: startDate,
        planedFinishDate: endDate,
        budgetQty: formData.budgetQty,
        uomId: formData.uomId,
        costCode: formData.costCode,
        taktCode: formData.taktCode,
        p6code: formData.p6code,
        activityTypeId: formData.activityTypeId,
        userId: userData.userId,
        projectIds: formData.projectIds,
      };

      // calling save api
      try {
        const responseData = await postData(saveSubTask, payloadData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const onCancelHandler = () => {
    clearSelection(selectActRef, "activityId");
    clearSelection(selectTaskRef, "taskId");
    clearSelection(selectUomRef, "uomId");

    setFormData({
      subTaskId: 0,
      companyId: companyData.companyId,
      activityId: 0,
      taskId: 0,
      subTaskCode: "",
      subTaskName: "",
      planedStartDate: "",
      planedFinishDate: "",
      budgetQty: 0,
      uomId: 0,
      costCode: "",
      taktCode: "",
      p6code: "",
      activityTypeId: 0,
      userId: userData.userId,
      projectIds: "",
    });

    setFormError({
      activityId: "",
      taskId: "",
      subTaskCode: "",
      subTaskName: "",
      planedStartDate: "",
      planedFinishDate: "",
      budgetQty: "",
      uomId: "",
      costCode: "",
      taktCode: "",
      p6code: "",
      activityTypeId: "",
      projectIds: "",
    });

    fetchActivityDrpDwn();
  };

  const activityOptions = activityDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onActDrpDownChange = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("activityId", selectedOption.value);
      setFormData({
        ...formData,
        activityId: parseInt(selectedOption.value),
      });
      fetchTaskDrpDwn(selectedOption.value);
    } else {
      validatefields("activityId", ""); // or any other default value
      setFormData({
        ...formData,
        activityId: 0, // Set to an empty string or any other default value
      });
    }
  };

  const taskOptions = taskDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onTaskDrpDownChange = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("taskId", selectedOption.value);
      setFormData({
        ...formData,
        taskId: parseInt(selectedOption.value),
      });

      fetchProjectDrpDwn(selectedOption.value);
    }
  };

  const options = uomDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    if (selectedOption) {
      validatefields("uomId", selectedOption.value);
      setFormData({
        ...formData,
        uomId: parseInt(selectedOption.value),
      });
    } else {
      validatefields("uomId", ""); // or any other default value
      setFormData({
        ...formData,
        uomId: 0, // Set to an empty string or any other default value
      });
    }
  };

  const activityTypeOptions = activityTypeDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onActTypeDrpDownChange = (selectedOption: any) => {
    console.log(selectedOption);
    if (selectedOption) {
      validatefields("activityTypeId", selectedOption.value);
      setFormData({
        ...formData,
        activityTypeId: parseInt(selectedOption.value),
      });
    } else {
      validatefields("activityTypeId", ""); // or any other default value
      setFormData({
        ...formData,
        activityTypeId: 0, // Set to an empty string or any other default value
      });
    }
  };

  const handleProjectSelect = (item: any) => {
    const selectedValuesString = item.map((prj: any) => prj.value).join(",");

    setFormData(() => ({
      ...formData,
      projectIds: selectedValuesString,
    }));
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageSubTask"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Add Sub Task
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageSubTask">
                      <FontAwesomeIcon icon={faGears} />
                      <span>Manage Sub Task</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Sub Task
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Activity Name<span className="text-danger">*</span>
                </label>
                <Select
                  ref={selectActRef}
                  name="activityId"
                  value={options.find(
                    (option) => option.value === formData.activityId
                  )}
                  onChange={onActDrpDownChange}
                  options={activityOptions}
                  placeholder="Select Activity"
                />
                <span className="text-danger">{formError.activityId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Activity Type <span className="text-danger">*</span>
                </label>
                <Select
                  ref={selectActTypeRef}
                  name="activityTypeId"
                  value={options.find(
                    (option) => option.value === formData.activityTypeId
                  )}
                  onChange={onActTypeDrpDownChange}
                  options={activityTypeOptions}
                  placeholder="Select Activity Type"
                />
                <span className="text-danger">{formError.activityTypeId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Task <span className="text-danger">*</span>
                </label>
                <Select
                  ref={selectTaskRef}
                  name="taskId"
                  value={options.find(
                    (option) => option.value === formData.taskId
                  )}
                  onChange={onTaskDrpDownChange}
                  options={taskOptions}
                  placeholder="Select Task"
                />
                <span className="text-danger">{formError.taskId}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Sub Task Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="subTaskCode"
                  value={formData.subTaskCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.subTaskCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Sub Task Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="subTaskName"
                  value={formData.subTaskName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.subTaskName}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Planned Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="planedStartDate"
                  value={formData.planedStartDate}
                  onChange={(event) => onDateChange(event, "planedStartDate")}
                />
                <span className="text-danger">{formError.planedStartDate}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">Planned Finish Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="planedFinishDate"
                  value={formData.planedFinishDate}
                  min={restrictDate}
                  onChange={(event) => onDateChange(event, "planedFinishDate")}
                />
                <span className="text-danger">
                  {formError.planedFinishDate}
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Budget Qty.</label>
                <input
                  type="number"
                  className="form-control"
                  name="budgetQty"
                  min={0}
                  value={formData.budgetQty === 0 ? 0 : formData.budgetQty}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.budgetQty}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  UOM <span className="text-danger">*</span>
                </label>
                <Select
                  ref={selectUomRef}
                  name="uomId"
                  value={options.find(
                    (option) => option.value === formData.uomId
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select UOM"
                />
                <span className="text-danger">{formError.uomId}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">Cost Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="costCode"
                  value={formData.costCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.costCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Takt Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="taktCode"
                  value={formData.taktCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.taktCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">P6 Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="p6code"
                  value={formData.p6code}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.p6code}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Projects <span className="text-danger">*</span>
                </label>
                <Multiselect
                  displayValue="label"
                  options={projectDrpDwnData}
                  onSelect={(item: any) => handleProjectSelect(item)}
                  onRemove={(item: any) => handleProjectSelect(item)}
                  showCheckbox
                  disable={projectDrpDwnData.length === 0}
                />
                <span className="text-danger">{formError.projectIds}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Clear</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
