import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBuildingFlag,
  faXmark,
  faSave,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import {
  getTaskActivityDrpDwn,
  getTaskUomDrpDwn,
  saveTask,
} from "../../../services/Endpoints";
import { getData, postData } from "../../../services/API";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthContext";
// import Select from 'react-select';
// import Multiselect from 'multiselect-react-dropdown';
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import Select from "react-select";
// import { Option } from "../../../components/common/MultiselectDropdown"

export interface IAddTaskProps {}

interface ITaskData {
  taskId: number;
  projectId: number;
  activityId: number;
  taskCode: string;
  taskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: number;
  uomId: number;
  costCode: string;
  taktCode: string;
  p6code: string;
  userId: number;
  // subTaskIdCommaSeperated: string
}

interface IFormErrorData {
  taskCode: string;
  taskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: string;
  uomId: string;
  costCode: string;
  taktCode: string;
  p6code: string;
  activityId: string;
  // subTaskIdCommaSeperated: string
}

export function AddTask() {
  // Declarations //
  const reRoute = useNavigate();
  const { userData, companyData, projectData } = useAuth();

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [restrictDate, setRestrictDate] = useState("");

  const [activityDrpDwnData, setActivityDrpDwnData] = useState<any[]>([]);
  // const [subTaskDrpDwnData, setSubTaskDrpDwnData] = useState<any[]>([]);
  const [uomDrpDwnData, setUOMDrpDwnData] = useState<any[]>([]);
  // const [selectedSubTask, setSelectedSubTask] = useState("");
  // const [optionSelected, setSelected] = useState<Option[] | null>();

  const [formData, setFormData] = useState<ITaskData>({
    taskId: 0,
    projectId: projectData.projectId,
    activityId: 0,
    taskCode: "",
    taskName: "",
    planedStartDate: "",
    planedFinishDate: "",
    budgetQty: 0,
    uomId: 0,
    costCode: "",
    taktCode: "",
    p6code: "",
    userId: userData.userId,
    // subTaskIdCommaSeperated: "",
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    taskCode: "",
    taskName: "",
    planedStartDate: "",
    planedFinishDate: "",
    budgetQty: "",
    uomId: "",
    costCode: "",
    taktCode: "",
    p6code: "",
    activityId: "",
    // subTaskIdCommaSeperated: ""
  });

  const selectActRef = useRef<any>(null);
  const selectUomRef = useRef<any>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  const clearSelection = (reff: any, filedName: any) => {
    setFormData({ ...formData, [filedName]: 0 });
    if (reff.current) {
      reff.current.clearValue();
    }
  };

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchActivityDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getTaskActivityDrpDwn + `ProjectId=${projectData.projectId}`
        );
        if (responseData.success) {
          setActivityDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // const fetchSubTaskDrpDwn = async () => {
    //   // Call getData API
    //   try {
    //     const responseData = await getData(getSubTaskDrpDwn + `ProjectId=${projectData.projectId}`);
    //     if (responseData.success) {
    //       setSubTaskDrpDwnData([]);
    //       responseData.data.forEach((item: any) => {
    //         setSubTaskDrpDwnData((prevState) => [
    //           ...prevState,
    //           { value: item.value, label: item.text }
    //         ]);
    //       });
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    const fetchUomDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getTaskUomDrpDwn + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setUOMDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchActivityDrpDwn();
    // fetchSubTaskDrpDwn();
    fetchUomDrpDwn();
  }, [companyData.companyId, projectData.projectId]);

  // const handleSubTaskSelect = (item: any) => {
  //   const selectedValuesString = item.map((staff: any) => staff.value).join(',');

  //   setFormData(() => ({
  //     ...formData,
  //     subTaskIdCommaSeperated: selectedValuesString
  //   }));
  // }

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageTask");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
    const spaceRegex = /^\s|\s$/;
    if (fieldName.includes("taskCode")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("taskName")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 200 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 200) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 200 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("uomId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "UOM is required." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("Qty")) {
      if (fieldValue === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue > 0) {
        if (fieldValue < 1) {
          setFormError({
            ...formError,
            [fieldName]: "Value must be greater than 1.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("activityId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "Activity is required." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("costCode")) {
      if (fieldValue !== null) {
        if (fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("taktCode")) {
      if (fieldValue !== null) {
        if (fieldValue !== null && fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("p6code")) {
      if (fieldValue !== null) {
        if (fieldValue !== null && fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.taskName.trim() === "") {
      err.taskName = "Task Name is required.";
    } else {
      if (validatefields("taskName", formData.taskName)) {
        err.taskName =
          "Must be between 2 to 100 characters, without empty spaces at the beginning.";
      }
    }

    if (formData.taskCode.trim() === "") {
      err.taskCode = "Task Code is required.";
    } else {
      if (validatefields("taskCode", formData.taskCode)) {
        err.taskCode =
          "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    // if (formData.planedStartDate === "") {
    //   err.planedStartDate = "Planned Start Date is required.";
    // }
    // if (formData.planedFinishDate === "") {
    //   err.planedFinishDate = "Planned Finish Date is required.";
    // }

    // if (formData.budgetQty === 0) {
    //   err.budgetQty = "Budget Qty is required.";
    // }
    if (validatefields("budgetQty", formData.budgetQty)) {
      err.budgetQty = "Value must be greater than 1.";
    }

    if (formData.uomId === 0 || formData.uomId.toString().length === 0) {
      err.uomId = "UOM is required.";
    }

    // if (formData.activityId === 0 || formData.activityId.toString().length === 0) {
    //   err.activityId = "Activity is required.";
    // }

    // if (formData.subTaskIdCommaSeperated === "") {
    //   err.subTaskIdCommaSeperated = "Sub Task is required.";
    // }

    if (validatefields("costCode", formData.costCode)) {
      err.costCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (validatefields("taktCode", formData.taktCode)) {
      err.taktCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (validatefields("p6code", formData.p6code)) {
      err.p6code =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onDateChange = (event: any, field: any) => {
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));

    // const date = new Date(event.target.value);
    if (field === "planedStartDate") {
      // setStartDate(date.toISOString());
      setRestrictDate(event.target.value);
    } else if (field === "planedFinishDate") {
      // setEndDate(date.toISOString());
    }
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      // calling save api
      try {
        const responseData = await postData(saveTask, formData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const onCancelHandler = () => {
    clearSelection(selectActRef, "activityId");
    clearSelection(selectUomRef, "uomId");

    setFormData({
      taskId: 0,
      projectId: projectData.projectId,
      activityId: 0,
      taskCode: "",
      taskName: "",
      planedStartDate: "",
      planedFinishDate: "",
      budgetQty: 0,
      uomId: 0,
      costCode: "",
      taktCode: "",
      p6code: "",
      userId: userData.userId,
      // subTaskIdCommaSeperated: "",
    });

    setFormError({
      taskCode: "",
      taskName: "",
      planedStartDate: "",
      planedFinishDate: "",
      budgetQty: "",
      uomId: "",
      costCode: "",
      taktCode: "",
      p6code: "",
      activityId: "",
      // subTaskIdCommaSeperated: ""
    });
  };

  const options = uomDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));
  const options1 = activityDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("uomId", selectedOption.value);
      setFormData({
        ...formData,
        uomId: selectedOption.value,
      });
    } else {
      validatefields("uomId", ""); // or any other default value
      setFormData({
        ...formData,
        uomId: 0, // Set to an empty string or any other default value
      });
    }
  };
  const onChangeHandler2 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("activityId", selectedOption.value);
      setFormData({
        ...formData,
        activityId: selectedOption.value,
      });
    } else {
      validatefields("activityId", ""); // or any other default value
      setFormData({
        ...formData,
        activityId: 0, // Set to an empty string or any other default value
      });
    }
  };

  // const handleChange = (selected: Option[]) => {
  //   setSelected(selected);
  //   const selectedValuesString = selected.map((staff: any) => staff.value).join(',');
  //   setFormData(() => ({
  //     ...formData,
  //     subTaskIdCommaSeperated: selectedValuesString
  //   }));
  // };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageTask"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Add Task
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageTask">
                      <FontAwesomeIcon icon={faBuildingFlag} />
                      <span>Manage Task</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Task
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Activity Name </label>
                <Select
                  ref={selectActRef}
                  name="activityId"
                  value={options1.find(
                    (option) => option.value === formData.activityId
                  )}
                  onChange={onChangeHandler2}
                  options={options1}
                  placeholder="Select Activity"
                />
                <span className="text-danger">{formError.activityId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Task Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="taskCode"
                  value={formData.taskCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.taskCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Task Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="taskName"
                  value={formData.taskName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.taskName}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">Planned Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="planedStartDate"
                  value={formData.planedStartDate}
                  onChange={(event) => onDateChange(event, "planedStartDate")}
                />
                <span className="text-danger">{formError.planedStartDate}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Planned Finish Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="planedFinishDate"
                  value={formData.planedFinishDate}
                  min={restrictDate}
                  onChange={(event) => onDateChange(event, "planedFinishDate")}
                />
                <span className="text-danger">
                  {formError.planedFinishDate}
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Budget Qty.</label>
                <input
                  type="number"
                  className="form-control"
                  name="budgetQty"
                  min={0}
                  value={formData.budgetQty === 0 ? "" : formData.budgetQty}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.budgetQty}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  UOM <span className="text-danger">*</span>
                </label>
                <Select
                  ref={selectUomRef}
                  name="uomId"
                  value={options.find(
                    (option) => option.value === formData.uomId
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select UOM"
                />
                <span className="text-danger">{formError.uomId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Cost Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="costCode"
                  value={formData.costCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.costCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Takt Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="taktCode"
                  value={formData.taktCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.taktCode}</span>
              </div>
              {/* <div className="col-md-4">
                <label className="form-label">Sub Task <span className="text-danger">*</span></label>
                <MultiSelect
                  key="sub_task"
                  options={subTaskDrpDwnData}
                  onChange={handleChange}
                  value={optionSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                  placeholder="Select Sub Task"
                />
                <span className="text-danger">{formError.subTaskIdCommaSeperated}</span>
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">P6 Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="p6code"
                  value={formData.p6code}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.p6code}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Clear</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
