import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  getTaskProjectMapping,
  getAllTaskDrpDwn,
  saveTaskProjectMapping,
  getProjectActivityDrpDwn,
} from "../../../services/Endpoints";
import { getData, postData } from "../../../services/API";
import { Link } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import activateImg from "../../../assets/activate.gif";
import { useAuth } from "../../../providers/AuthContext";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

export interface IEditTaskProps {}

interface ITaskData {
  activityId: number;
  taskIds: string;
}

interface IFormErrorData {
  activityId: string;
  taskIds: string;
}

export function EditTaskProjectMapping() {
  // Declarations //
  const { userData, projectData, companyData } = useAuth();

  const [formData, setFormData] = useState<ITaskData>({
    activityId: 0,
    taskIds: "",
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    activityId: "",
    taskIds: "",
  });

  const [activityDrpDwnData, setActivityDrpDwnData] = useState<any[]>([]);
  const [taskDrpDwnData, setTaskDrpDwnData] = useState<any[]>([]);
  const [taskDefaultData, setTaskDefaultData] = useState<any[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions //
  useEffect(() => {
    fetchActivityDrpDwn();
  }, []);

  const fetchActivityDrpDwn = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getProjectActivityDrpDwn + `ProjectId=${projectData.projectId}`
      );
      if (responseData.success) {
        setActivityDrpDwnData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTaskDrpDwn = async (activityId: number) => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllTaskDrpDwn + `ActivityId=${activityId}`
      );
      if (responseData.success) {
        setTaskDrpDwnData([]);
        responseData.data.forEach((item: any) => {
          setTaskDrpDwnData((prevState) => [
            ...prevState,
            { value: item.value, label: item.text },
          ]);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTaskData = async (activityId: number) => {
    // Call getDataById API
    try {
      const responseData = await getData(
        getTaskProjectMapping +
          `ProjectId=${projectData.projectId}&ActivityId=${activityId}`
      );
      if (responseData.success) {
        var dat = responseData.data;

        var selectedValuesString: string = "";

        if (dat.selectedIds !== null) {
          if (dat.selectedIds.length > 0) {
            setTaskDefaultData([]);
            dat.selectedIds.forEach((item: any) => {
              setTaskDefaultData((prevState) => [
                ...prevState,
                { value: item.value, label: item.text },
              ]);
            });

            selectedValuesString = dat.selectedIds
              .map((data: any) => data.value)
              .join(",");
          }
        }

        setFormData(() => ({
          activityId: activityId,
          taskIds: selectedValuesString,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const activityOptions = activityDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));

  const onActDrpDownChange = (selectedOption: any) => {
    if (selectedOption) {
      setTaskDrpDwnData([]);
      setTaskDefaultData([]);
      setFormData({
        ...formData,
        activityId: selectedOption.value,
      });
      fetchTaskDrpDwn(selectedOption.value);
      fetchTaskData(selectedOption.value);
    } else {
      setFormData({
        ...formData,
        activityId: 0, // Set to an empty string or any other default value
      });
    }
  };

  const handleTaskSelect = (item: any) => {
    const selectedValuesString = item.map((dat: any) => dat.value).join(",");
    setFormData(() => ({
      ...formData,
      taskIds: selectedValuesString,
    }));

    setTaskDefaultData(item);

    setIsChanged(true);
  };

  const handleSuccessAlert = () => {
    setFormData({
      activityId: 0,
      taskIds: "",
    });

    setIsChanged(false);

    setActivityDrpDwnData([]);
    setTaskDrpDwnData([]);
    setTaskDefaultData([]);

    setFormError({
      activityId: "",
      taskIds: "",
    });

    fetchActivityDrpDwn();
    setShowSuccessAlert(false);
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const onSubmitHandler = () => {
    if (formData.taskIds !== "") {
      setIsSubmitting(true);
      saveTaskMapping();
    } else {
      setShowWarningAlert(true);
    }
  };

  const saveTaskMapping = async () => {
    setShowWarningAlert(false);
    if (!isSubmitting) {
      const payload = {
        activityId: formData.activityId,
        projectId: projectData.projectId,
        taskIds: formData.taskIds,
        userId: userData.userId,
      };

      // calling save api
      try {
        const responseData = await postData(saveTaskProjectMapping, payload);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Task Mapping</h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Task Mapping
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3">
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Activity Name <span className="text-danger">*</span>
                </label>
                <Select
                  name="activityId"
                  value={
                    activityOptions.find(
                      (option) => option.value == formData.activityId
                    ) || []
                  }
                  onChange={onActDrpDownChange}
                  options={activityOptions}
                  placeholder="Select Activity"
                />
                <span className="text-danger">{formError.activityId}</span>
              </div>
              <div className="col-md-8">
                <label className="form-label">
                  Task <span className="text-danger">*</span>
                </label>
                <Multiselect
                  displayValue="label"
                  options={taskDrpDwnData}
                  selectedValues={taskDefaultData}
                  onSelect={(item: any) => handleTaskSelect(item)}
                  onRemove={(item: any) => handleTaskSelect(item)}
                  disable={taskDrpDwnData.length === 0}
                  showCheckbox
                />
                <span className="text-danger">{formError.taskIds}</span>
              </div>
            </div>
            <div className="d-flex justify-content-start mt-3">
              <button
                type="button"
                className="btn custom-btn"
                disabled={!isChanged}
                hidden={isSubmitting}
                onClick={onSubmitHandler}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showWarningAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showWarningAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  Tasks will be deleted for selected Activity..!!
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={() => {
                    setIsSubmitting(true);
                    saveTaskMapping();
                  }}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
