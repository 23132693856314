import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSave,
  faXmark,
  faBuilding,
  faMagnifyingGlass,
  faUpload,
  faArrowLeft,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  deleteCompanyLogo,
  getCompanyDataById,
  saveCompany,
} from "../../../services/Endpoints";
import { getData, getDataById, postData } from "../../../services/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
import defaultImg from "../../../assets/placeholder-img.png";
import Select from "react-select";
import { countries } from "../../../services/countries";

export interface IEditCompanyProps {}

interface ICompanyData {
  companyId: number;
  companyName: string;
  companyEmailId: string;
  password: string;
  companyMobileNo: string;
  companyLogobase64: string;
  fileName: string;
  logedInUserId: number;
}

interface IFormErrorData {
  companyName: string;
  companyEmailId: string;
  password: string;
  companyMobileNo: string;
  companyLogobase64: string;
  fileName: string;
  countryname: string;
}

export function EditCompany() {
  // Declarations //
  const { Id } = useParams();
  const reRoute = useNavigate();
  const { userData } = useAuth();

  const [formData, setFormData] = useState<ICompanyData>({
    companyId: 0,
    companyName: "",
    companyEmailId: "",
    password: "Password",
    companyMobileNo: "",
    companyLogobase64: "",
    fileName: "",
    logedInUserId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    companyName: "",
    companyEmailId: "",
    password: "",
    companyMobileNo: "",
    companyLogobase64: "",
    fileName: "",
    countryname: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  const [selectedFile, setSelectedFile] = useState<string>("");
  const [countryname, setCountryname] = useState<string>("");
  const [countrycode, setCountrycode] = useState<number>(0);

  const handleFileButtonClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png,.jpeg,.jpg"; // Specify accepted file types if needed
    fileInput.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target?.result as string;
          setSelectedFile(base64String);
          setFormData((prevState) => ({
            ...prevState,
            companyLogobase64: base64String, // Updating companyLogobase64 field with selected base64 string
            fileName: file.name,
          }));
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchCompanyData = async () => {
      // Call getDataById API
      try {
        const responseData = await getDataById(getCompanyDataById, Id);
        if (responseData.success) {
          var dat = responseData.data;
          var extractedCountryCode = "";
          var extractedMobileNo = "";
          if (
            dat.companyMobileNo.length > 10 &&
            dat.companyMobileNo.startsWith("+")
          ) {
            const cntName: any = countries.find((option) =>
              dat.companyMobileNo.startsWith(option.dial_code)
            );
            if (cntName) {
              setCountryname(`${cntName.name}(${cntName.dial_code})`);
              setCountrycode(cntName.dial_code);
              extractedCountryCode = cntName.dial_code;
              extractedMobileNo = dat.companyMobileNo.replace(
                extractedCountryCode,
                ""
              );
            }
          }
          setFormData({
            companyId: dat.companyId,
            companyName: dat.companyName,
            companyEmailId: dat.companyEmailId,
            password: dat.decryptedPassword,
            companyMobileNo:
              extractedMobileNo === ""
                ? dat.companyMobileNo
                : extractedMobileNo,
            companyLogobase64: dat.companyLogo,
            fileName: "",
            logedInUserId: userData.userId,
          });
          setSelectedFile(dat.companyLogo);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCompanyData();
  }, [Id, userData.userId]);

  const fetchCompanyData = async () => {
    // Call getDataById API
    try {
      const responseData = await getDataById(getCompanyDataById, Id);
      if (responseData.success) {
        var dat = responseData.data;
        var extractedCountryCode = "";
        var extractedMobileNo = "";
        if (
          dat.companyMobileNo.length > 10 &&
          dat.companyMobileNo.startsWith("+")
        ) {
          const cntName: any = countries.find((option) =>
            dat.companyMobileNo.startsWith(option.dial_code)
          );
          if (cntName) {
            setCountryname(cntName.name);
            setCountrycode(cntName.dial_code);
            extractedCountryCode = cntName.dial_code;
            extractedMobileNo = dat.companyMobileNo.replace(
              extractedCountryCode,
              ""
            );
          }
        }
        setFormData({
          companyId: dat.companyId,
          companyName: dat.companyName,
          companyEmailId: dat.companyEmailId,
          password: dat.decryptedPassword,
          companyMobileNo:
            extractedMobileNo === "" ? dat.companyMobileNo : extractedMobileNo,
          companyLogobase64: dat.companyLogo,
          fileName: "",
          logedInUserId: userData.userId,
        });
        setSelectedFile(dat.companyLogo);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageCompany");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validateNumeric = (entText: any) => {
    // Regular expression for numeric validation
    // const numRegex = /^[0-9]*$/;
    const numRegex = /^[0-9+ -]*$/;
    return numRegex.test(entText);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const spaceRegex = /^\s|\s$/;
    if (
      fieldName.includes("Name") ||
      fieldName.toLowerCase().includes("password")
    ) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || spaceRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("EmailId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 100) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 100 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("countryname")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "") {
        setFormError({
          ...formError,
          [fieldName]: "Country Name is required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("MobileNo")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]: "Empty spaces are not allowed.",
        });
        return true;
      } else if (
        fieldValue.length < 10 ||
        fieldValue.length > 16 ||
        !validateNumeric(fieldValue)
      ) {
        setFormError({ ...formError, [fieldName]: "Invalid Contact Number." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.companyName.trim() === "") {
      err.companyName = "Company Name is required.";
    } else {
      if (validatefields("companyName", formData.companyName)) {
        err.companyName =
          "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.";
      }
    }

    if (formData.companyEmailId.trim() === "") {
      err.companyEmailId = "Company Email Id is required.";
    } else if (!validateEmail(formData.companyEmailId)) {
      err.companyEmailId = "Invalid email format.";
    } else {
      if (validatefields("companyEmailId", formData.companyEmailId)) {
        err.companyEmailId =
          "Must be between 2 and 100 characters, without empty spaces at the beginning.";
      }
    }

    if (countryname.trim() === "") {
      err.countryname = "Country Name is required.";
    }

    if (formData.password.trim() === "") {
      err.password = "Password is required.";
    } else {
      if (validatefields("password", formData.password)) {
        err.password =
          "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning";
      }
    }

    if (formData.companyMobileNo.trim() === "") {
      err.companyMobileNo = "Company Mobile Number is required.";
    } else if (validatefields("companyMobileNo", formData.companyMobileNo)) {
      err.companyMobileNo = "Invalid Contact Number.";
    } else if (!validateNumeric(formData.companyMobileNo)) {
      err.companyMobileNo = "Invalid Contact Number.";
    }

    // if (formData.companyLogobase64 === "") {
    //   err.companyLogobase64 = "Company Logo is required.";
    // }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      const payLoad = {
        companyId: formData.companyId,
        companyName: formData.companyName,
        companyEmailId: formData.companyEmailId,
        password: formData.password,
        companyMobileNo:
          countrycode !== 0
            ? countrycode + formData.companyMobileNo
            : formData.companyMobileNo,
        companyLogobase64: formData.companyLogobase64,
        fileName: formData.fileName,
        logedInUserId: userData.userId,
      };

      // calling save api
      try {
        const responseData = await postData(saveCompany, payLoad);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    // setFormData({
    //   companyId: 0,
    //   companyName: "",
    //   companyEmailId: "",
    //   companyMobileNo: "",
    //   companyLogobase64: "",
    //   fileName: "",
    //   logedInUserId: userData.userId
    // });

    // setFormError({
    //   companyName: "",
    //   companyEmailId: "",
    //   companyMobileNo: "",
    //   companyLogobase64: "",
    //   fileName: "",
    // });

    // setSelectedFile("");
    reRoute("/manageCompany");
  };

  const onDeleteProfile = async () => {
    try {
      const responseData = await getData(
        deleteCompanyLogo +
          `CompanyId=${formData.companyId}&UserId=${formData.logedInUserId}`
      );
      if (responseData.success) {
        //console.log(responseData.data);
        fetchCompanyData();
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const options = countries.map((opt) => ({
    value: opt.dial_code,
    label: `${opt.name}(${opt.dial_code})`,
  }));

  const onChangeHandler1 = (selectedOption: any, event: any) => {
    if (selectedOption) {
      validatefields(event.name, selectedOption.value);
      setCountrycode(selectedOption.value);
      setCountryname(selectedOption.label);
    } else {
      validatefields(event.name, "");
      setCountrycode(0);
      setCountryname("");
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageCompany"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Edit Company
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageCompany">
                      <FontAwesomeIcon icon={faBuilding} />
                      <span>Manage Company</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Company
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-3">
                <label className="form-label">
                  Company Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  value={formData.companyName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.companyName}</span>
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  Company EmailId <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="companyEmailId"
                  value={formData.companyEmailId}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.companyEmailId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.password}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-3">
                <label className="form-label">
                  Country<span className="text-danger">*</span>
                </label>
                <Select
                  name="countryname"
                  value={options.find((option) => option.label === countryname)}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select Countries"
                />
                <span className="text-danger">{formError.countryname}</span>
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  Company Mobile No. <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="companyMobileNo"
                  value={formData.companyMobileNo}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.companyMobileNo}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="upload-area">
                  <FontAwesomeIcon icon={faUpload} size="2xl" />
                  {/* <span className="upload-text mt-2">Click to upload or drag and drop</span> */}
                  <span className="upload-size-text mt-2">
                    Max. File Size: 30MB
                  </span>
                  <button
                    type="button"
                    className="btn custom-btn mt-2"
                    onClick={handleFileButtonClick}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ marginRight: 5 }}
                    />
                    Browse File
                  </button>
                </div>
                <span className="text-danger">
                  {formError.companyLogobase64}
                </span>
              </div>
              <div className="col-md-5">
                {/* {selectedFile && <img src={selectedFile} alt="Company Logo" style={{ width: 300, height: 170, borderRadius: 12 }} />} */}
                {selectedFile === "" ? (
                  <img
                    src={defaultImg}
                    alt="default-thumbnail"
                    style={{ width: 200, height: 150, borderRadius: 5 }}
                  />
                ) : (
                  <img
                    src={selectedFile}
                    alt="company-logo"
                    style={{ width: 200, height: 150, borderRadius: 5 }}
                  />
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-danger mb-1"
                  onClick={onDeleteProfile}
                  style={{ marginLeft: "10px", marginTop: 115 }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700 }}>
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700 }}>
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
