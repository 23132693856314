export interface IStatusBtnProps {}

export function StatusBtn(props: any) {
  return (
    <div style={{ width: 20, alignContent: "center" }}>
      {props.colDef.headerName === "Activity Status" ? (
        <label
          className={`${
            props.data.activityStatus == ""
              ? ""
              : props.data.activityStatus === "Completed"
              ? "btn btn-sm btn-success mb-1"
              : "btn btn-sm btn-warning mb-1"
          }`}
          style={{ width: "90px" }}
        >
          {props.data.activityStatus}
        </label>
      ) : (
        <label
          className={`btn btn-sm ${
            props.data.activeFlag == true || props.data.activeFlag == 1
              ? "btn-success"
              : "btn-danger"
          } mb-1"`}
          style={{ width: "90px", fontWeight: 600 }}
        >
          {props.data.activeFlag == true || props.data.activeFlag == 1
            ? "Active"
            : "Inactive"}
        </label>
      )}
    </div>
  );
}
