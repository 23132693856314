import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileCircleCheck,
  faFileExcel,
  faHouse,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect } from "react";
import {
  getDailyActivityReport,
  getProjectDropdownList,
} from "../../../services/Endpoints";
import { getData, postData } from "../../../services/API";
import { useAuth } from "../../../providers/AuthContext";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { StatusBtn } from "../../../components/common/StatusBtns";
import { format } from "date-fns";
import { ExportToExcel } from "../../../services/ExportToFile";

interface IActivityReportData {
  companyId: number;
  projectId: number;
  startDate: string;
  endDate: string;
  userId: number;
}

interface IFormErrorData {
  projectId: string;
  startDate: string;
  endDate: string;
}

interface IDailyReportRowData {
  dailyActivityId: number;
  activityItemName: string;
  activityName: string;
  taskName: string;
  subTaskName: string;
  dailyActivityName: string;
  dailyActivityDate: string;
  projectLevelNames: string;
  quantity: string;
  activityProgress: number;
  activityStatus: string;
  shift: string;
  totalHrsSpent: number;
  labourType: string;
  contractorLabourSupply: string;
  activityType: string;
  weather: string;
  remarks: string;
  activityCreatedBy: string;
  manpowers: Manpowers[];
  manpowersCommaSeparated: string;
}

interface Manpowers {
  manpowerCode: string;
  manpowerName: string;
  designationName: string;
}

export function DailyActivityReport() {
  // Declarations //
  const reRoute = useNavigate();
  const location = useLocation();

  const { userData, companyData, projectData, userType } = useAuth();
  const [projectDrpDwnData, setProjectDrpDwnData] = useState<any[]>([]);

  const [dailyReportRowData, setDailyReportRowData] =
    useState<IDailyReportRowData[]>();

  const [formData, setFormData] = useState<IActivityReportData>({
    companyId: companyData.companyId,
    projectId: 0,
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    projectId: "",
    startDate: "",
    endDate: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  useEffect(() => {
    const fetchProjectDrpDwn = async () => {
      // Call getData API
      try {
        setProjectDrpDwnData([]);
        const responseData = await getData(
          getProjectDropdownList + `CompanyId=${companyData.companyId}`
        );
        if (responseData.success) {
          setProjectDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchAllDailyActivityReport = async () => {
      const pstData: any = {
        companyId: companyData.companyId,
        projectId:
          userType.userTypeCode === "CADM"
            ? Number(formData.projectId)
            : projectData.projectId,
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
        userId: userData.userId,
      };
      // calling save api
      try {
        const responseData = await postData(getDailyActivityReport, pstData);
        console.log(responseData);
        if (responseData.success) {
          if (responseData.data != null && responseData.data.length > 0) {
            setDailyReportRowData([]);

            // Update each item in the response data
            const updatedData = responseData.data.map(
              (item: IDailyReportRowData) => {
                // Extract manpower names from the manpowers array
                const manpowerNames = item.manpowers.map(
                  (manpower) => manpower.manpowerName
                );
                // Join the names into a comma-separated string
                const manpowersCommaSeparated = manpowerNames.join(", ");

                // Return the updated item with the new key
                return {
                  ...item,
                  manpowersCommaSeparated: manpowersCommaSeparated,
                };
              }
            );

            setDailyReportRowData(updatedData);
          }
        } else {
          setDailyReportRowData([]);
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    };

    fetchAllDailyActivityReport();
    fetchProjectDrpDwn();
  }, [companyData.companyId]);

  const [columnDefs] = useState<ColDef[]>([
    { headerName: "Sl.No", valueGetter: "node.rowIndex + 1", width: 100 },
    {
      headerName: "Activity Item Name",
      field: "activityItemName",
      // filter: true,
      width: 150,
      tooltipField: "activityItemName",
      headerTooltip: "Activity Item Name",
    },
    {
      headerName: "Activity Name",
      field: "activityName",
      // filter: true,
      width: 150,
      tooltipField: "activityName",
      headerTooltip: "Activity Name",
      editable: true,
    },
    {
      headerName: "Task Name",
      field: "taskName",
      // filter: true,
      width: 150,
      tooltipField: "taskName",
      headerTooltip: "Task Name",
    },
    {
      headerName: "Subtask Name",
      field: "subTaskName",
      // filter: true,
      width: 150,
      tooltipField: "subTaskName",
      headerTooltip: "Subtask Name",
    },
    {
      headerName: "Daily Activity Name",
      field: "dailyActivityName",
      // filter: true,
      width: 180,
      tooltipField: "dailyActivityName",
      headerTooltip: "Daily Activity Name",
    },
    {
      headerName: "Daily Activity Date",
      field: "dailyActivityDate",
      // filter: true,
      width: 180,
      tooltipField: "dailyActivityDate",
      headerTooltip: "Daily Activity Date",
    },
    {
      headerName: "Project Level Names",
      field: "projectLevelNames",
      // filter: true,
      width: 180,
      tooltipField: "projectLevelNames",
      headerTooltip: "Project Level Names",
    },
    {
      headerName: "Quantity",
      field: "quantity",
      // filter: true,
      width: 180,
      tooltipField: "quantity",
      headerTooltip: "Quantity",
    },
    {
      headerName: "Activity Progress",
      field: "activityProgress",
      // filter: true,
      width: 180,
      tooltipField: "activityProgress",
      headerTooltip: "Activity Progress",
    },
    {
      headerName: "Activity Status",
      field: "activityStatus",
      cellRenderer: StatusBtn,
      // filter: false,
      sortable: false,
    },
    {
      headerName: "Shift",
      field: "shift",
      // filter: true,
      width: 150,
      tooltipField: "shift",
      headerTooltip: "Shift",
    },
    {
      headerName: "Total Hrs Spent",
      field: "totalHrsSpent",
      // filter: true,
      width: 150,
      tooltipField: "totalHrsSpent",
      headerTooltip: "Total Hrs Spent",
    },
    {
      headerName: "Labour Type",
      field: "labourType",
      // filter: true,
      width: 150,
      tooltipField: "labourType",
      headerTooltip: "Labour Type",
    },
    {
      headerName: "Contractor Labour Supply",
      field: "contractorLabourSupply",
      // filter: true,
      width: 150,
      tooltipField: "contractorLabourSupply",
      headerTooltip: "Contractor Labour Supply",
    },
    {
      headerName: "Activity Type",
      field: "activityType",
      // filter: true,
      width: 150,
      tooltipField: "activityType",
      headerTooltip: "Activity Type",
    },
    {
      headerName: "Foreman",
      field: "activityCreatedBy",
      // filter: true,
      width: 150,
      tooltipField: "activityCreatedBy",
      headerTooltip: "Foreman",
    },
    {
      headerName: "Manpowers",
      field: "manpowersCommaSeparated",
      // filter: true,
      width: 150,
      tooltipField: "manpowersCommaSeparated",
      headerTooltip: "Manpowers",
    },
    {
      headerName: "Weather",
      field: "weather",
      // filter: true,
      width: 150,
      tooltipField: "weather",
      headerTooltip: "Weather",
    },
    {
      headerName: "Remarks",
      field: "remarks",
      // filter: true,
      width: 150,
      tooltipField: "remarks",
      headerTooltip: "Remarks",
    },
  ]);

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    if (fieldName.includes("projectId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue == 0) {
        setFormError({
          ...formError,
          [fieldName]: "Project selection required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("startDate")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "") {
        setFormError({
          ...formError,
          [fieldName]: "Start Date selection required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("endDate")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "") {
        setFormError({
          ...formError,
          [fieldName]: "End Date selection required.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (userType.userTypeCode === "CADM") {
      if (formData.projectId !== 0) {
        if (validatefields("projectId", formData.projectId)) {
          err.projectId = "Project selection required.";
        }
      }
    }

    if (formData.startDate.trim() == "") {
      err.startDate = "Start Date is required.";
    } else {
      if (validatefields("startDate", formData.startDate)) {
        err.startDate = "FromDate selection required.";
      }
    }

    if (formData.endDate.trim() == "") {
      err.endDate = "Request Date is required.";
    } else {
      if (validatefields("endDate", formData.endDate)) {
        err.startDate = "End Date selection required.";
      }
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      const pstData: any = {
        companyId: companyData.companyId,
        projectId:
          userType.userTypeCode === "CADM"
            ? Number(formData.projectId)
            : projectData.projectId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        userId: userData.userId,
      };
      // calling save api
      try {
        const responseData = await postData(getDailyActivityReport, pstData);

        if (responseData.success) {
          if (responseData.data != null && responseData.data.length > 0) {
            setDailyReportRowData([]);

            // Update each item in the response data
            const updatedData = responseData.data.map(
              (item: IDailyReportRowData) => {
                // Extract manpower names from the manpowers array
                const manpowerNames = item.manpowers.map(
                  (manpower) => manpower.manpowerName
                );
                // Join the names into a comma-separated string
                const manpowersCommaSeparated = manpowerNames.join(", ");

                // Return the updated item with the new key
                return {
                  ...item,
                  manpowersCommaSeparated: manpowersCommaSeparated,
                };
              }
            );

            setDailyReportRowData(updatedData);
          }
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          setIsSubmitting(false);
        } else {
          setDailyReportRowData([]);
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onSubmitCancelHandler = () => {
    setFormData({
      companyId: companyData.companyId,
      userId: userData.userId,
      projectId: 0,
      startDate: "",
      endDate: "",
    });

    setFormError({
      projectId: "",
      startDate: "",
      endDate: "",
    });
  };

  const onExportToExcel = () => {
    const columns = JSON.stringify(
      columnDefs.map((column) => {
        return {
          label: column.headerName,
          value: column.headerName === "Sl.No" ? "slNo" : column.field,
        };
      })
    );

    let content = "{}";
    if (dailyReportRowData !== undefined) {
      let data = JSON.stringify(
        dailyReportRowData.map((row, index) => {
          return {
            slNo: index + 1,
            activityItemName: row.activityItemName,
            activityName: row.activityName,
            taskName: row.taskName,
            subTaskName: row.subTaskName,
            dailyActivityName: row.dailyActivityName,
            dailyActivityDate: row.dailyActivityDate,
            projectLevelNames: row.projectLevelNames,
            quantity: row.quantity,
            activityProgress: row.activityProgress,
            activityStatus: row.activityStatus,
            shift: row.shift,
            totalHrsSpent: row.totalHrsSpent,
            labourType: row.labourType,
            contractorLabourSupply: row.contractorLabourSupply,
            activityType: row.activityType,
            activityCreatedBy: row.activityCreatedBy,
            manpowersCommaSeparated: row.manpowersCommaSeparated,
            weather: row.weather,
            remarks: row.remarks,
          };
        })
      );

      content = data;
    }

    ExportToExcel("Daily Activity Report", columns, content);
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <div className="d-flex gap-2 align-items-center">
                  {location.search.split("?")[1] === "adminDashboard" && (
                    <button
                      className="btn bg-light"
                      onClick={() => reRoute(-1)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  Daily Activity Report
                </div>
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Daily Activity Report
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Project Name <span className="text-danger">*</span>
                </label>
                <select
                  name="projectId"
                  className={`form-select ${
                    userType.userTypeCode !== "CADM" ? "read-only" : ""
                  }`}
                  value={
                    userType.userTypeCode !== "CADM"
                      ? projectData.projectId
                      : formData.projectId
                  }
                  onChange={onChangeHandler}
                >
                  {userType.userTypeCode === "CADM" && <option>All</option>}
                  {projectDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>
                      {opt.text}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{formError.projectId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Start Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="startDate"
                  value={formData.startDate}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.startDate}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  End Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  value={formData.endDate}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.endDate}</span>
              </div>
            </div>

            <div className="d-flex justify-content-start mt-3 gap-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onSubmitCancelHandler}
                disabled={isSubmitting}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Clear</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon
                  icon={faFileCircleCheck}
                  style={{ marginRight: 5 }}
                />
                <span>Report</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Reporting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      {dailyReportRowData !== undefined && dailyReportRowData?.length > 0 && (
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h6>Daily Activity Report</h6>
              </div>
              <div className="d-flex align-items-center gap-2">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={onExportToExcel}
                >
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    style={{ marginRight: 5 }}
                  />
                  <span>Export Excel</span>
                </button>
              </div>
            </div>
            <div className="mt-3">
              <div className="ag-theme-quartz" style={{ height: 380 }}>
                <AgGridReact
                  rowData={dailyReportRowData}
                  columnDefs={columnDefs}
                  tooltipShowDelay={500}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <Link to="/dailyActivityReport">
                  <button
                    type="button"
                    className="btn custom-btn"
                    onClick={handleSuccessAlert}
                    style={{ width: 183.5 }}
                  >
                    Okay
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
